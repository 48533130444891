export const getEmailsListFromUsersById = (usersList) => {
    if (!Array.isArray(usersList)) return []
    const studentsEmails = []
    if (usersList?.length < 1) return
    // eslint-disable-next-line
    usersList.map((plan) => {
        studentsEmails.push(plan.emails[0].address)
    }
    )
    return { studentsEmails: [...new Set(studentsEmails)], parentsEmails: [] }
}
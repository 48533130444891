import io from 'socket.io-client'


const connection = io(
  //'ws://localhost:3000',
  'wss://dev.api2.zinkerz.com:4000',
  {transports: ['websocket']},
)


const useSocketConnection = () => {
  return {
    connection,
  }
}

export default useSocketConnection

import { useLazyQuery, gql } from '@apollo/client'

export const useGetUsersById = (onSuccess = () => { }, onError = () => { }) => {
    // Sirve para traer usuarios por id
    return useLazyQuery(
        gql`query (
        $userIds: [ID!]
              )
              {
                users(
                    userIds: $userIds
    ){
                    id
                    firstName
                    lastName
                    avatar
                    emails {
                        address
                        verified
                    }
               
            }
        }
            `,
        {
            fetchPolicy: 'no-cache',
            onCompleted: onSuccess,
            onError,
        },
    )

}
import React, { Fragment, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../i18n/useTranslation'
import useColumns from './useColumns'
import UseColumnsEducators from './useComunEducators'
import UseColumnsStaffs from './useColumnsStaff'
import UseColumnsParents from './useColumnParents'
import useCountries from '../.././Model/Countries/List'
import { gql, useLazyQuery } from '@apollo/client'
import {
  Button,
  IconSignPlus,
  ConfirmModal,
} from '@Knowledge-OTP/znk-ui-components'
import EditGlobalRate from './Components/globalRate'
import EditCustomService from './Components/editCumtomService'
import AddServiceCustom from './Components/addServiceCustom'
import InfoStudent from '../Student/modal/InfoStudent/index'
import InfoEducator from '../Educator/modal/InfoEducator/index'
import InfoParent from '../Parent/modal/infoParent/index'
// import InfoParent from '../Parent/index'
import ProfileView from './Modal/Profile'
import AddParentToStudentModal from './Modal/Student/addParentToStudent'
import SetEducatorUnavailabilityModal from './Modal/Educator/setEducatorUnavailability'
import UserInvoicesModalForm from './Modal/BilledInvoices'
import SetEducatorInfoModal from './Modal/Educator/setEducatorInfo'
import './index.css'
import {
  TabContent,
  TabPane
} from 'reactstrap'
import OptionsEmailsAndDownloads from '../../common/ModalEmailsList/Components/Options'

import useSendEmail from '../../Model/Users/Student/sendEmailPaymentMethod'
import { showAlert } from '../../common/Alert/util'
import useStaffModal from '../Staff/useStaffModal'
import ConfirmActions from './Components/confirmActions/confirmActions'
//CREATE USERS
import useCreateParentModal from '../Parent/modal/createParent/useCreateParentModal'
import CreateParentModal from '../Parent/modal/createParent'
import useCreateStudentModal from '../Plans/modal/newPlan/Components/NewStudent/useCreateStudentModal'
import NewStudent from '../Plans/modal/newPlan/Components/NewStudent'
import useCreateEducatorModal from '../Educator/modal/CreateEducator/useCreateEducatorModal'
import CreateEducatorAndStaffModal from '../Educator/modal/CreateEducator'
//MAILS LIST
import useModalEmailsList from '../../common/ModalEmailsList/useModalEmailsList'
import useExcelDownload from '../../common/ExcelDownload/useExcelDownload'
import ExcelDownloadComponent from '../../common/ExcelDownload'
import NoAuthorize from '../../common/NoAuthorize'

import ComponentTable from './Tables/ComponentTable'

//Universities list
import useUniversitiesNames from './Model/useUniversitiesNames'
import { useParams } from 'react-router'

const UsersView = props => {
  let { user, role, tab } = useParams()
  const dispatch = useDispatch()
  const { translate } = useTranslation()

  const [users, setUsersTab] = useState([])
  // eslint-disable-next-line
  const [query, setQuery] = useState([])
  const [studentsRef, setStudentsRef] = useState({})
  const [educatorsRef, setEducatorsRef] = useState({})
  const [staffsRef, setStaffsRef] = useState({})
  const [parentsRef, setParentsRef] = useState({})
  const [render, setRender] = useState(true)
  const { isOpen, userId, type } = useSelector((state) => state.modal.user.addPaymentMethod)
  const { isOpen: openActivate, dataUser } = useSelector((state) => state.modal.student.setActivate)
  const { open: openCreateUserModal, isOpen: isOpenCreateParent } = useCreateParentModal()
  const { open: openCreateStudent, isOpen: isOpenCreateStudent } = useCreateStudentModal()
  const { open: openCreateEducator, isOpen: isOpenCreateEducator } = useCreateEducatorModal()
  const UseColumnsStudents = useColumns()

  const [getUserInfo] = useLazyQuery(
    gql`
        query($id: String!) {
        user(id: $id) {
          id
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
            notifications {
              missedLesson
              lessonsNotesReady
              lessonReminder {
                isAvailable
                duration
              }
            }
            servicesRate {
              defaultRate
              name
              serviceId
            }
            parents {
              parentUserId
              managePayments
              manageApprovals
              firstName
              lastName
              email
            }
          }
          isActive
          roles
          educatorInfo {
            disableForStudents
            availabilityBlocks {
                day
                hour
            }
          }
            }
        }
    `,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (user && role) {
      getUserInfo({variables: {id: user}})
      .then(
        data => {
          ViewProfile(data?.data?.user, role, tab ?? null)
        }
      )
    }
    // eslint-disable-next-line
  }, [user, role, tab])
  const refetch = () => {
    if (studentsRef?.refetch) studentsRef.refetch()
    if (educatorsRef?.refetch) educatorsRef.refetch()
    if (staffsRef?.refetch) {
      staffsRef.refetch()
    }
    if (parentsRef?.refetch) parentsRef.refetch()
  }

  const UseColumnsStaff = UseColumnsStaffs()
  const UseColumnsParen = UseColumnsParents()
  const { open: openStaffModal } = useStaffModal()
  const { open: openExcelDownloadModal } = useExcelDownload()
  const [loadingExcelInfo, setLoadingExcelInfo] = useState(false)
  const [execDownload, setExecDownload] = useState(false)
  const { staffInfo } = useSelector((state) => state.user)
  const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes('users');
  const {
    setEducatorUnavailability,
    addParentToStudent,
    setEducatorInfo,
    invoices,
    globalRate,
    customService,
    addCustomService,
    profile } = useSelector((state) => state.modal.user)
  const { isOpen: isOpenInfoStudentModal } = useSelector((state) => state.modal.student.infoStudentModal)
  const { isOpen: isOpenInfoEducatorModal } = useSelector((state) => state.modal.educator.infoEducatorModal)
  const { isOpen: isOpenInfoParentModal } = useSelector((state) => state.modal.parent.infoParentModal)
  const { isOpen: isOpenEducatorUnavailability } = setEducatorUnavailability
  const { isOpen: isOpenAddParentToStudent } = addParentToStudent
  const { isOpen: isOpenSetEducatorInfo } = setEducatorInfo
  const { isOpen: isOpenInvoices } = invoices
  const { isOpen: isOpenGlobalRate } = globalRate
  const { isOpen: isOpenCustomService } = customService
  const { isOpen: isOpenAddCustomService } = addCustomService
  const { isOpen: isOpenProfile } = profile

  // Filters States
  const [servicesFilters] = useState([])
  // const [namesDataFilters] = useState([])
  const dataServices = useSelector((state) => state.user.services)
  const { data: countries } = useCountries({ limit: 0 })
  // Pagination States
  const [shortcuts, setShortcuts] = useState({
    students: true,
    educators: false,
    staff: false,
    parents: false
  })

  const currentRole = useMemo(
    () => {
      if (shortcuts.educators) return 'EDUCATOR'
      else if (shortcuts.staff) return 'SUPER_ADMIN'
      else if (shortcuts.parents) return 'PARENT'
      //if(shortcuts.students) 
      return 'STUDENT'
    }, [shortcuts]
  )

  const { data: universitiesData } = useUniversitiesNames({

    limit: 0,
  })

  useEffect(() => {
    if (universitiesData?.docs && render) {
      const universitiesToStore = universitiesData.docs.concat([{ id: "0000", name: "Other" }])
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'user.educatorAcademicInfo',
        data: {
          universitiesList: universitiesToStore
        }
      })
      setRender(false)
    }
    // eslint-disable-next-line
  }, [universitiesData])


  // Modal lista de mails
  const { open: openEmailsListModal } = useModalEmailsList()

  const getColumns = () => {

    if (shortcuts.students) {
      return UseColumnsStudents
    } else if (shortcuts.educators) {
      return UseColumnsEducator
    } else if (shortcuts.staff) {
      return UseColumnsStaff
    } else if (shortcuts.parents) {
      return UseColumnsParen
    }
  }



  // Functions
  const setCurrentShortcut = (shortcutKey) => {
    const newShortcut = {
      students: false,
      educators: false,
      staff: false
    }
    newShortcut[shortcutKey] = true
    setShortcuts(newShortcut)
  }
  const openCreateUser = () => {
    switch (true) {
      case shortcuts.students === true:
        openCreateStudent()
        break
      case shortcuts.educators === true || shortcuts.staff === true:
        openCreateEducator()
        break
      // case shortcuts.staff === true:
      //   openCreateUserModal()
      //   break
      case shortcuts.parents === true:
        openCreateUserModal()
        break
      default:
        console.error('case not found')
    }
  }
  const ViewProfile = (user, type, tab = '') => {
    if (type === 'educator') {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'educator.infoEducatorModal',
        data: {
          isOpen: true,
          full: false,
          userId: user.id,
          type: type,
          initPage: 'profile',
          pageProfile: 'full-profile',
          tab
        }
      })
    } else if (type === 'student') {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'student.infoStudentModal',
        data: {
          isOpen: true,
          dataStudent: user,
          initPage: 'profile',
          full: false,
          profilePage: 'viewProfile',
          tab
        }
      })
    } else if (type === 'parent') {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'parent.infoParentModal',
        data: {
          isOpen: true,
          userId: user.id,
          dataStudent: user,
          initPage: 'profile',
          type: type,
          full: false,
          profilePage: 'viewProfile',
          tab
        }
      })
    } else if (type === 'staff') {
      openStaffModal(user, () => {
        if (staffsRef?.refetch) staffsRef.refetch()
      })
    }
  }


  const [getActivities, { data: dataActivities }] = useLazyQuery(
    gql`
      query (
          $serviceIds: [String!]
          $kinds: [String!]
          $from: DateTime
          $to: DateTime
      ) {
        allActivities(
            kinds: $kinds,
            serviceIds: $serviceIds
            from: $from,
            to: $to
        ){
            ... on Test {
              id
              name
              kind
              tbd
              startDate
              endDate
              busy
              serviceId
              registrationDate
              scoreReleaseDate
              isDisabled
            }
            ... on Event {
                id
                name
                kind
                tbd
                startDate
                endDate
                busy
                description
            }
        }
      }
    `,
    { variables: { serviceIds: servicesFilters.map(e => { return e.id }), kinds: ['test'] } }
  )
  useEffect(() => {
    if (dataActivities?.allActivities) {
    }
  }, [dataActivities])
  useEffect(() => {
    getActivities()
    // eslint-disable-next-line
  }, [])


  const updateList = () => {
    refetch()
  }
  const UseColumnsEducator = UseColumnsEducators(refetch)

  const executeOnClickDownload = () => {
    openExcelDownloadModal(() => { }, shortcuts, 
    currentRole === 'STUDENT' ? studentsRef?.query :
    currentRole === 'EDUCATOR' ? educatorsRef?.query :
    currentRole === 'PARENT' ? parentsRef?.query :
    currentRole === 'SUPER_ADMIN' ? staffsRef?.query : null
    ) 
    setExecDownload(true) 
  }

  const { submit, loading: loadingEmail } = useSendEmail(() => { sentEmail() }, () => { })
  const sentEmail = () => {
    showAlert({
      text: translate('student.modal.payments.successsent'),
      status: 'success'
    }, dispatch)
    setConfirm(false)
  }

  const onExecute = () => {
    submit({ email: userId })
  }
  const setConfirm = (value) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'user.addPaymentMethod',
      data: {
        isOpen: value
      }
    })
  }
  const closeActivate = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.setActivate',
      data: {
        isOpen: false
      }
    })
  }
  if (!isPermited) {
    return <NoAuthorize />
  }


  const tableProps = {
    onClick: (item) => {
      // if (shortcuts.educators) return
      if (shortcuts.staff) {
        openStaffModal(item, () => {
          refetch()
        })
      } else {
        ViewProfile(item, shortcuts.students ? 'student' : shortcuts.parents ? 'parent' : 'educator')
      }
    },
    loading: false,
    // tableData={data && !isEmpty(data) ? data.docs : []}
    columnList: getColumns(),
    tabLinks: (
      <div className='d-flex flex-grow-1 mt-2 mt-md-0 znk-scroll-horizontal'>
        <span className={`d-flex flex-row align-items-center nav-title h3 mx-3 font-weight-bold ${!shortcuts.students ? '' : 'active'}`} onClick={() => setCurrentShortcut('students')}>Students</span>
        <span className={`d-flex flex-row align-items-center nav-title h3 mx-3 font-weight-bold ${!shortcuts.educators ? '' : 'active'}`} onClick={() => setCurrentShortcut('educators')}>Educators</span>
        <span className={`d-flex flex-row align-items-center nav-title h3 mx-3 font-weight-bold ${!shortcuts.staff ? '' : 'active'}`} onClick={() => setCurrentShortcut('staff')}>Staff</span>
        <span className={`d-flex flex-row align-items-center nav-title h3 mx-3 font-weight-bold ${!shortcuts.parents ? '' : 'active'}`} onClick={() => setCurrentShortcut('parents')}>Parents</span>
      </div>
    ),
    options: (
      <OptionsEmailsAndDownloads
        options={[{ title: translate('users.actions.emails'), onClick: () => { openEmailsListModal(() => { }, shortcuts, currentRole) } },
        { title: translate('users.actions.download'), onClick: () => { executeOnClickDownload()} },
        ]}
        loading={loadingExcelInfo}
      />
    ),
    setUsers: setUsersTab,
    setQuery
  }
  return (
    <Fragment>
      {
        isOpen && (
          <ConfirmModal
            className='m-4'
            toggle={() => setConfirm(false)}
            isOpen
            loading={loadingEmail}
            title={type === 'payment' ? translate('student.modal.payments.paymenttitle') : translate('payments.modal.ignorepayment.title')}
            body={type === 'payment' ? translate('student.modal.payments.paymentbody') : translate('payments.modal.ignorepayment.title')}
            confirmAction={onExecute}
            cancelAction={() => setConfirm(false)}
            confirmText={type === 'payment' ? translate('student.modal.payments.paymenttext') : translate('payments.modal.ignorepayment.title')}
            cancelText={translate('plans.modal.cancelplan.cancel')}
          />
        )
      }

      <TabContent activeTab={currentRole} className={`h-100`}>
        <TabPane tabId={`STUDENT`} className={`h-100 not-animation`}>
          <ComponentTable
            {...tableProps}
            role={'STUDENT'}
            tableRef={studentsRef}
            setTableRef={setStudentsRef}
          />
        </TabPane>
        <TabPane tabId={`EDUCATOR`} className={`h-100  not-animation`}>
          <ComponentTable
            {...tableProps}
            role={'EDUCATOR'}
            tableRef={educatorsRef}
            setTableRef={setEducatorsRef}
          />
        </TabPane>
        <TabPane tabId={`PARENT`} className={`h-100  not-animation`}>
          <ComponentTable
            {...tableProps}
            role={'PARENT'}
            tableRef={parentsRef}
            setTableRef={setParentsRef}
          />
        </TabPane>
        <TabPane tabId={`SUPER_ADMIN`} className={`h-100 not-animation`}>
          <ComponentTable
            {...tableProps}
            role={'SUPER_ADMIN'}
            tableRef={staffsRef}
            setTableRef={setStaffsRef}
          />
        </TabPane>
      </TabContent>
      <ExcelDownloadComponent loadingExcelInfo={loadingExcelInfo} setLoadingExcelInfo={setLoadingExcelInfo} 
      execDownload={execDownload} setExecDownload={setExecDownload}
      />
      {
        shortcuts.students ?
          <NewStudent dataQuery={{}} callback={() => { }} refetch={updateList} sendEmailOption={true}>
            <Button
              className='position-fixed p-0'
              color='primary'
              size={'small'}
              style={{ bottom: '15px', right: '120px', width: 'fit-content',/**   heigth: '50px',**/ boderRadius: '25px' }}
              label={
                <span className='p-2'>
                  <IconSignPlus size={15} className={`m-3`} style={{ transform: 'rotate(45deg)' }} />
                </span>
              }
              onClick={() =>
                // dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'user.create', data: { isOpen: true } })
                openCreateUser()
              }
            />
          </NewStudent> :
          <Button
            className='position-fixed p-0'
            color='primary'
            size={'small'}
            style={{ bottom: '15px', right: '120px', width: 'fit-content',/**   heigth: '50px',**/ boderRadius: '25px' }}
            label={
              <span className='p-2'>
                <IconSignPlus size={15} className={`m-3`} style={{ transform: 'rotate(45deg)' }} />
              </span>}
            onClick={() =>
              // dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'user.create', data: { isOpen: true } })
              openCreateUser()
            }
          />
      }
      {isOpenEducatorUnavailability && <SetEducatorUnavailabilityModal />}
      {isOpenAddParentToStudent && <AddParentToStudentModal allUsers={users || []} />}
      {isOpenSetEducatorInfo && <SetEducatorInfoModal />}
      {isOpenInvoices && <UserInvoicesModalForm />}
      {isOpenGlobalRate && <EditGlobalRate />}
      {isOpenCustomService && <EditCustomService services={dataServices ? dataServices.docs ?? dataServices : []} />}
      {isOpenAddCustomService && <AddServiceCustom services={dataServices ? dataServices.docs ?? dataServices : []} />}
      {isOpenProfile && <ProfileView countries={countries} refetch={updateList} />}
      {isOpenInfoStudentModal && <InfoStudent refetch={updateList} />}
      {isOpenInfoEducatorModal && <InfoEducator countries={countries} refetch={updateList} />}
      {isOpenInfoParentModal && <InfoParent countries={countries} refetch={updateList} />}
      {/* Create Parents modal */}
      {isOpenCreateParent && <CreateParentModal countries={countries} refetch={updateList} />}
      {isOpenCreateEducator && <CreateEducatorAndStaffModal countries={countries} shortcuts={shortcuts} refetch={updateList} />}
      {isOpenCreateStudent && <NewStudent refetch={updateList} />}
      {openActivate && <ConfirmActions isOpen={openActivate} setIsOpen={closeActivate} refetch={updateList} data={dataUser} />}
    </Fragment>
  )
}
export default UsersView

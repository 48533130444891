import React, {useMemo, useState} from 'react'
import {
    Avatar,
    DropDownItem,
    DropDownNew,
    IconErase,
    IconOptions,
    IconSignCheck,
    IconWspAlert,
    ListItem,
    IconAlert,
    IconLoad
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import useSendWspAlertModal from '../Modal/SendWspAlert/useSendWspAlertModal'
import useDismissNotification from '../Modal/DismissNotification/useDismissNotification'
// import useShowScoresModal from '../../../../../CounselingTemplates/Task/viewTaskModal/modals/ScoresModal/useShowScoresModal'
// import useSetScoresToPlan from '../Model/useSetScoresToPlan'
// import {showAlert} from '../../../../../../common/Alert/util'
import useHandlerExamForm from '../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useHandlerExamForm'
import { FORMODEL as FOR_MODEL_EXAM_SCORE } from '../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useHandlerExamForm'

import { useCallback } from 'react'
import './index.css'
import {Badge} from 'reactstrap'
// import useGetScorePlan from '../Modal/GetScorePlan/useGetScorePlan'

const PlanListItem = (props) => {
    const {
        className = '',
        plan = {},
        type = 'getScore',
        refetch = () => {},
        ...otherProps
    } = props
    const {open: openWspAlert, close: closeWspAlert} = useSendWspAlertModal()
    const {open: openDismiss, close: closeDismiss} = useDismissNotification()
    // const {open: openScore, close: closeScore} = useShowScoresModal()
    const {open: openSubmitScoreModal} = useHandlerExamForm(FOR_MODEL_EXAM_SCORE.lessonPerWeekPlan)
    const services = useSelector((state) => state.user.services)
    const [studentName, testDateLabel, registrationDate, scoreReleaseDate/** , planId*/] = useMemo(
        () => {
            if(plan) {
                const thisService = services.find(({id}) => id === plan?.serviceId)
                return [
                    `${plan?.studentUser?.firstName} ${plan?.studentUser?.lastName}`,
                    `${thisService?.name} - ${moment(plan?.testDate).format('MMM Do, YYYY')}`,
                    plan?.activityTestDate?.registrationDate || plan?.activityTestDate?.startDate, 
                    plan?.activityTestDate?.scoreReleaseDate,
                    //plan?.id
                    // moment().toISOString(), moment().toISOString()
                ]
            }
            return []
            //eslint-disable-next-line
        }, [plan]
    )
    const currentDate = new Date()
    const dateDeadLine = new Date(registrationDate)
    const calculateRemainingDays = (date1, date2) => {
        const diffTime = Math.abs(date2 - date1)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays
    }
    const openModalWspAlert = useCallback(() => {
        openWspAlert(() => {
            refetch()
            closeWspAlert()
        }, {id: plan?.studentUser?.studentUserId}, type, plan)
        //eslint-disable-next-line
    }, [plan, type])

    const openDismissModal = useCallback(() => {
        openDismiss(() => {
            refetch()
            closeDismiss()
        }, [plan?.id] || [], type)
        //eslint-disable-next-line
    }, [plan. type])


    // const [setScoreToPlan] = useSetScoresToPlan(
    //     () => {
    //         refetch()
    //         showAlert({text: 'Success!', status: 'success'}, dispatch)
    //         closeScore()
    //     },
    //     (err) => {
    //         console.error(err)
    //         showAlert({text: err.toString(), status: 'error'}, dispatch)
    //     }
    // )
    const [loading, setLoading] = useState(false)
    const openSubmitScore = useCallback(() => {
        // const exam = plan?.selectedExam?.scores || plan?.examScores
        // const parsePlan = {
        //     ...plan, status: 'pending', 
        //     selectedExam: {
        //         ...plan?.examScores /**agrega id, name y serviceId**/, 
        //         ...exam /**agrega scores**/
        //     }
        // }
        
        setLoading(true)
        openSubmitScoreModal(() => {
        }, plan?.id, plan?.serviceId, FOR_MODEL_EXAM_SCORE.lessonPerWeekPlan).then(() => {}).catch(() => {}).finally(() => {setLoading(false)})
        // openScore((examInput) => {
        //     const variables = {
        //         examScore: examInput,
        //         examScoreId: plan?.examScores?.id,
        //         planId: plan?.id
        //     }
        //     setScoreToPlan({variables})
        // }, parsePlan, false, true)
        //eslint-disable-next-line
    }, [plan])

    const options = useMemo(
        () => {
            return type === 'getScore' ?
            [
                {
                    component: 
                        <label className={`px-2 w-100 d-flex flex-row align-items-center justify-content-between`}>
                            <span className={`font-weight-bold text-info mr-1`}>SRD: </span>
                            <span className={`text-gray`}>{moment(scoreReleaseDate).format('Do MMM, YYYY')}</span>
                        </label>
                },
                {component: <DropDownItem onClick={openSubmitScore} className={`text-info align-items-center`}>
                    <div className='d-flex flex-row justify-content-start align-items-center m-0 p-0'>
                        {loading ? <IconLoad size={16} className={'mr-1'}/> : <IconSignCheck size={16}/>} <span>Submit Score</span>
                    </div>
                    </DropDownItem>},
                {component: <DropDownItem onClick={openModalWspAlert} className={`text-info align-items-center`}><IconWspAlert size={16}/> Send wsp reminder</DropDownItem>},
                {component: <DropDownItem onClick={openDismissModal} className={`text-error danger-option align-items-center`}><IconErase size={16}/> Dismiss</DropDownItem>},
            ] : 
            [ //testRegistration
            {
                component: 
                <label className={`px-2 w-100 d-flex flex-row align-items-center justify-content-between`}>
                            <span className={`font-weight-bold text-info mr-1`}>DL: </span>
                            <span className={`text-gray`}>{moment(registrationDate).format('Do MMM, YYYY')}</span>
                        </label>
                },
                {component: <DropDownItem onClick={openModalWspAlert} className={`text-info align-items-center`}><IconWspAlert size={16}/> Send wsp reminder</DropDownItem>},
                {component: <DropDownItem onClick={openDismissModal} className={`text-error danger-option align-items-center`}><IconErase size={16}/> Dismiss</DropDownItem>},
            ]
        }, [type, scoreReleaseDate, registrationDate, openSubmitScore, openDismissModal, openModalWspAlert, loading]
    )

    return (
        <ListItem
            showBottomLine
            hasHover
            classNameLeft={'w-80'}
            className={`px-4 py-3 hover-no-border ${className}`}
            Left={
                <div className={`d-flex flex-row align-items-center`}>
                    <Avatar
                        name={studentName}
                        className={`mr-2`}
                        size={`avatar-medium`}
                    />
                    <div className={`d-flex flex-column text-truncate`}>
                        <span className={`text-info`}>
                            {studentName}
                        </span>
                        <span className={`text-gray h45 text-truncate`}>{`${testDateLabel}`}</span>
                    </div>
                </div>
            }
            Right={
                <div className={`d-flex flex-row align-items-center`}>
                    <div  style={{ display: 'flex', alignItems: 'center' }}>
                        {calculateRemainingDays(currentDate, dateDeadLine) <= 7 ? (
                            <>
                            <Badge pill color='danger' className='mr-1 filters' style={{display: 'flex', alignItems: 'center'}}>
                                <IconAlert size={1} className={`alert-icon pr-1`} style={{color: '#b61b00'}} />
                                <label style={{cursor: 'pointer'}} className='m-0 p-0 badge'>
                                Deadline approaching
                                </label> 
                            </Badge>
{/* 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div className={`hover-icon icon-alert-deadline`} >
                                        <IconAlert className={`alert-icon`} />
                                    </div>
                                </div> */}
                            </>
                        ) : null}
                    </div>
                    <DropDownNew
                        direction={`bottomRight`}
                        options={options}
                    >
                        <div className={`d-flex flex-row align-items-center`}>
                            <div className={`hover-icon`}>
                                <IconOptions className={`rotate-90`} />
                            </div>
                        </div>
                    </DropDownNew>
                </div>
            }
            {...otherProps}
        />
    );
    
}

export default PlanListItem
import React from 'react'
import {
    ListItem,
    IconArrowRight,
    CheckboxOption,
    IconBank,
    IconAddUniversity,
    LogoItem,
    EarlyDecisionBadge,
    EarlyActionBadge
} from '@Knowledge-OTP/znk-ui-components'
import useUniveristyDirectory from '../../../../../University/UniversityDirectory/useUniversityDirectory'
import useMyCollegeModal from '../../../../../University/MyCollegeModal/useMyCollegeModal'
import CompleteUniversityInfo from '../../../../../University/Utils'

const Universities = (props) => {
    const { selector = false, universities = null, setSelected, selected, plan, onAddUniversity } = props
    //university directory
    const { open: openUniversityDirectory } = useUniveristyDirectory()
    const selectUniversity = (id) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((idUniversity) => idUniversity !== id))
        }
        else setSelected(selected.concat([id]))
    }

    const { open: openMyCollegeModal } = useMyCollegeModal()

    const infoToObtain = ['name',
        'short_description',
        'city',
        'state_abbr',
        'type_category',
        'undergraduate_size',
        'acceptance_rate',
        'four_year_graduation_rate',
        'rankings_best_colleges',
        'sat_average',
        'act_cumulative_midpoint',
        'type_year',
        'avg_cost_of_attendance',
        'website',
    ]

    const showCollege = async (college, infoToObtain = []) => {
        if (!college?.isPrincipal) {
            const collegeComplete = await CompleteUniversityInfo(college, infoToObtain)
            // CompleteUniversityInfo(college, infoToObtain, (newUniversity) => {
            openMyCollegeModal(() => {
                onAddUniversity()
            }, collegeComplete, plan?.studentUser?.studentUserId, plan?.id, universities)
            // })
        } else {
            openMyCollegeModal(() => {
                onAddUniversity()
            }, college, plan?.studentUser?.studentUserId, plan?.id, universities)
        }
    }


    return (
        <div className={`d-flex flex-column`} >
            {
                (universities || []).map((item) => {
                    return (
                        <ListItem
                            className={'pt-1'}
                            hasHover
                            showBottomLine
                            onClick={async () => {
                                if (!selector) {
                                    showCollege(item, infoToObtain)
                                }
                            }}
                            Left={
                                <div className={`d-flex flex-row ml-2`}>
                                    {/* eslint-disable-next-line */}
                                    <div className={`d-flex flex-row justify-content-center mb-2`} style={{
                                        width: selector ? '25px' : '40px',
                                        height: selector ? '25px' : '40px'
                                    }}>

                                        {selector ?
                                        // eslint-disable-next-line
                                            item?.logo ? <img style={{ alignSelf: 'center', width: '25px', height: '25px'}} src={item?.logo} alt={item?.name} /> :

                                                <div className={`d-flex flex-row justify-content-center`} style={{
                                                    width: '25px',
                                                    height: '25px'
                                                }}>
                                                    <IconBank className={`align-self-center`} color={'gray'} size={25} />
                                                </div>


                                            : <LogoItem
                                                className='ml-4 mr-2 bg-transparent'
                                                size={40}
                                                early={item?.early}
                                                image={item?.logo}
                                                status={item?.status}
                                                showTolTip={true}
                                                tooltipId={`${item?.id}-${item?.status}`}
                                            />}
                                    </div>
                                    <span className={`align-self-center ml-4 text-info`}
                                    // style={{color: '#2C3F78'}}
                                    >{item?.name}</span>
                                </div>
                            }
                            Right={
                                selector ?
                                    <CheckboxOption
                                        onChange={(e) => {
                                            selectUniversity(item?.id)
                                        }}
                                        defaultState={selected?.includes(item.id)}
                                        value={selected?.includes(item.id)}
                                    /> :
                                    <div className={`hover-icon`}>{item?.early === 'action' ? <EarlyActionBadge /> :
                                    ['decision', 'decision2'].includes(item?.early) ? <EarlyDecisionBadge edVersion={item?.early}/> : <IconArrowRight className='mr-4' />}</div>
                            }
                        />

                    )
                })
            }
            <ListItem
                hasHover
                showBottomLine
                onClick={() => {
                    openUniversityDirectory(() => {
                        onAddUniversity()
                    }, plan?.studentUser?.studentUserId/**userId**/, plan?.id, universities)
                }}
                Left={
                    <div className={`d-flex flex-row ${selector ? 'ml-3' : 'ml-2'}`}>
                        <IconAddUniversity size={selector ? 25 : 40} />
                        <span className={`align-self-center ml-4 text-primary`}>Add University</span>
                    </div>
                }
                Right={
                    <div className={`hover-icon ${selector ? '' : 'mr-4'}`}><IconArrowRight /></div>
                }
            />
        </div>
    )
}

export default Universities
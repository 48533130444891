import React, { useMemo, useState } from 'react'
import {
    Button,
    LessonItem
} from '@Knowledge-OTP/znk-ui-components'
import useCompletePlanMutation from '../../Model/useCompletePlan'
import { showAlert } from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useLessons from '../../Model/useLessons'
import { TabContent, TabPane, Collapse } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../i18n/useTranslation'

const STATUSES = {
    PENDING: 'pending',
    SCHEDULED: 'scheduled'
}

const InnerCompletePlanModal = (props) => {
    const {
        onChange = () => { }, //onClick del Button 
        dataPlan, useMutation,
        onChecked = () => { }, //envia actualización de lecciones seleccionadas
        //cada vez que cambia
        // onPrevious = () => {}, 
        // onNext = () => {},
        actionMode = 'button' } = props


    const { translate, replaceVariables } = useTranslation()

    const dispatch = useDispatch()
    const [submit, { loading }] = useCompletePlanMutation(
        () => {
            if (useMutation) {
                showAlert({
                    text: translate("plans.modal.completeplan.onSuccess") || 'Plan completed successfully!',
                    status: 'success'
                }, dispatch)
            }
            if (onChange) onChange()
        },
        (err) => {
            //Plan cannot will complete, it's have scheduled lessons.
            showAlert({
                text: translate(err?.message?.toString() || "plans.modal.completeplan.onErrorHasPendingLessons"),
                status: 'error'
            }, dispatch)
            console.error(err)
        }
    )

    const { data, loading: loadingQuery } = useLessons({
        limit: 0, status: [STATUSES.PENDING, STATUSES.SCHEDULED], planId: dataPlan?.id
    })
    const lessons = useMemo(
        () => {
            return data?.lessons?.docs.filter(({ status }) => status === STATUSES.PENDING) || []
        }, [data]
    )

    const scheduledLessons = useMemo(
        () => {
            return data?.lessons?.docs.filter(({ status }) => status === STATUSES.SCHEDULED) || []
        }, [data]
    )

    const [selectedLessons, setSelectedLessons] = useState([])

    const allSelected = useMemo(() => {
        return lessons.length === selectedLessons.length
    }, [lessons, selectedLessons])
    const selectOrUnselectAll = () => {
        if (allSelected) {
            setSelectedLessons([])
            onChecked(lessons, false)
        }
        else {
            setSelectedLessons(lessons.map(({ id }) => id))
            onChecked(lessons, true)
        }
    }


    const RenderLessonItem = (props) => {
        const { lesson, ...otherProps } = props
        return (
            <LessonItem
                dataLesson={lesson}
                useCheck
                checked={selectedLessons.includes(lesson.id)}
                onChangeCheck={(bool) => {
                    onChecked([lesson], bool)
                    if (bool && !selectedLessons.includes(lesson.id)) {
                        setSelectedLessons(selectedLessons.concat([lesson.id]))
                    }
                    else if (!bool) {
                        setSelectedLessons(selectedLessons.filter((id) => id !== lesson.id))
                    }
                }}
                {...otherProps}
            />
        )
    }

    return (
        <>
            <div>
                <div className={`w-100 d-flex flex-row justify-content-between m-0 p-0`}>
                    <span className={`text-gray h45`}>{translate("plans.modal.completeplan.scheduledLessonsLabel") || "Scheduled lessons"}</span>
                    <div className={`d-flex flex-row`}>
                        <span className={`h45 text-gray`}>
                            {
                                replaceVariables(translate("plans.modal.completeplan.totalScheduledLessonsLabel"), { LESSONS: scheduledLessons.length })
                                || `${scheduledLessons.length} lessons`
                            }
                        </span>
                    </div>
                </div>
                <TabContent style={{maxHeight: '200px', overflowY: 'scroll'}} activeTab={loadingQuery ? 'loading' : scheduledLessons.length > 0 ? 'show' : 'blank'}>
                    <TabPane tabId={`loading`}>
                        <Skeleton count={5} height={40} className={`w-100`} />
                    </TabPane>
                    <TabPane tabId={`blank`}>
                        <label className={`my-6 text-gray h45 text-center w-100`}>{translate("plans.modal.completeplan.noScheduledLessonsLabel") || "No scheduled lessons"}</label>
                    </TabPane>
                    <TabPane tabId={`show`}>
                        <div className={`w-100`}>
                            {scheduledLessons?.map((lesson) => <RenderLessonItem lesson={lesson} useCheck={false} />)}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
            <div className='mt-5'>
                <div className={`w-100 d-flex flex-row justify-content-between m-0 p-0`}>
                    <span className={`text-gray h45`}>{translate("plans.modal.completeplan.pendingLessonsLabel") || "Pending lessons"}</span>
                    <div className={`d-flex flex-row`}>
                        <span className={`h45 text-gray`}>
                            {
                                replaceVariables(translate("plans.modal.completeplan.totalPendingLessonsLabel"), { LESSONS: lessons.length })
                                || `${lessons.length} lessons`
                            }
                        </span>
                        {
                            lessons.length > 0 && (
                                <>
                                    <span className={`text-gray h45 mx-2`}>/</span>
                                    <span className={`h45 btn-link`} onClick={selectOrUnselectAll}>
                                        {allSelected ? ` ${translate("plans.modal.completeplan.unselectAllLabel") || "Unselect all"}` : ` ${translate("plans.modal.completeplan.selectAllLabel") || "Select all"}`}
                                    </span>
                                </>
                            )
                        }
                    </div>
                </div>
                <TabContent style={{maxHeight: '200px', overflowY: 'scroll'}} activeTab={loadingQuery ? 'loading' : lessons?.length > 0 ? 'show' : 'blank'}>
                    <TabPane tabId={`loading`}>
                        <Skeleton count={5} height={40} className={`w-100`} />
                    </TabPane>
                    <TabPane tabId={`blank`}>
                        <label className={`my-6 text-gray h45 text-center w-100`}>{translate("plans.modal.completeplan.noPendingLessonsLabel") || "No pending lessons"}</label>
                    </TabPane>
                    <TabPane tabId={`show`}>
                        <div className={`w-100 mt-3 mb-5 p-0`} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            {lessons.map((lesson) => <RenderLessonItem lesson={lesson} />)}
                        </div>
                    </TabPane>
                </TabContent>
                <Collapse isOpen={selectedLessons.length > 0 && actionMode === 'button'}>
                    <label className={`text-error h45`}>{translate("plans.modal.completeplan.selectLessonsWillRemoveLabel") || "Selected lessons will be remove"}</label>
                </Collapse>
            </div>
            {
                actionMode === 'button' && (
                    <Collapse isOpen={scheduledLessons.length > 0}>
                        {
                            scheduledLessons.length > 0 && (
                                <span className='text-gray h45'>{translate("plans.modal.completeplan.cannotForScheduledLessonsLabel")}
                                    {/* <span className='btn-link text-primary' onClick={() => { setGlobalPane(STATUSES.SCHEDULED) }}>{translate("plans.modal.completeplan.scheduledLessonsLabel")}</span> */}
                                </span>
                            )
                        }
                    </Collapse>
                )
            }
            {
                actionMode === 'button' ?
                    <Button
                        size={`large`}
                        className={`w-100`}
                        color={`primary`}
                        activity={loading}
                        disabled={scheduledLessons.length > 0}
                        label={translate("plans.modal.completeplan.completePlanLabel") || `Complete Plan`}
                        onClick={() => {
                            if (useMutation) submit({
                                variables: {
                                    planId: dataPlan?.id,
                                    pendingLessonsId: selectedLessons
                                }
                            })
                            else {
                                if (onChange) onChange(lessons.filter(({ id }) => selectedLessons.includes(id)))
                            }
                        }}
                    /> : (null)
                // <div className={`d-flex flex-row justify-content-between w-100`}>
                //     <span className={`btn-link text-error h45`}
                //         onClick={() => {
                //             onPrevious()
                //         }}
                //     >Previous</span>
                //     <span className={`btn-link text-primary h45`}

                //         onClick={() => {
                //             onNext()
                //         }}
                //     >Next</span>
                // </div>
            }


        </>
    )
}

export default InnerCompletePlanModal
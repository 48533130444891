import React, { useMemo } from 'react'
import { Stats, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import StudentField from './Fields/Student'
import ServiceField, { ServiceHover } from './Fields/Service'
import TopicsField, { getTopicIdsInPlan } from './Fields/Topics'
import PlanDurationField, { PlanDurationHover } from './Fields/PlanDuration'
import CostField from './Fields/Cost'
import StatusField from './Fields/Status'
import TableSelectorField from './Fields/TableSelector'
import ProgressPlanHover from './Fields/ProgressPlanHover'
import ProgressGroupPlanHover from './Fields/ProgressGroupPlanHover'
import ProgressGroupStudentHover from './Fields/ProgressGroupStudentHover'
import moment from 'moment-timezone'
import CompactPlanInfoField from './Fields/CompactPlanInfo'
import GroupStudents from './Fields/GroupStudents'
import Atom from '../../assets/Atom.svg'

const useResponsivePlanColumns = (props) => {
  const { breakWidth } = useWindowDimensions()
  const { getServiceById, query, data, plans,  getStudentById, refetch } = props
  const typename = plans?.docs?.length > 0 ? plans?.docs[0].__typename : null
  return useMemo(() => {
    if (query.kind.includes('group')) {
      if( !['GroupPLan'].includes(typename) ) return []
      if(breakWidth === 'SM') return [
        { render: item => {
          return (
            <GroupStudents students={(item?.studentPlans || [])?.filter(({planStatus}) => planStatus !== 'cancel')?.map(item => getStudentById(item.studentId))} />
          )
        }, colSpan: 6, id: 'avatar' },
        // { render: item => item.name, colSpan: 5, hover: (item) => item.name, type: 'tooltip', className: 'text-secondary', id: 'planName' },
        {
          render: (item) => {
            return <StatusField plan={item} status={item.status} />
          },
          hover: (item) => {
            return <ProgressGroupPlanHover item={item} isHover={item.isHover} />
          },
          type: 'tooltip',
          colSpan: 4,
          className: 'justify-content-end align-items-center text-secondary',
          id: 'status'
        },
        {
          optionsParser: (item) => {
            return TableSelectorField(item, {
              thisService: getServiceById(item.serviceId),
              thisTopics: getTopicIdsInPlan(item.parts || item.fixedParts, item.kind),
              queryData: data,
              queryVar: query
            }, refetch)
          },
          colSpan: 2,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }
      ]
      else if(breakWidth === 'MD') return [
        { render: item => {
          return (
            <GroupStudents students={(item?.studentPlans || [])?.filter(({planStatus}) => planStatus !== 'cancel')?.map(item => getStudentById(item.studentId))} />
          )
        }, colSpan: 4, id: 'avatar' },
        // { render: item => item.name, colSpan: 5, hover: (item) => item.name, type: 'tooltip', className: 'text-secondary', id: 'planName' },
        {
          render: (item) => {
            return <StatusField plan={item} status={item.status} />
          },
          hover: (item) => {
            return <ProgressGroupPlanHover item={item} isHover={item.isHover} />
          },
          type: 'tooltip',
          colSpan: 6,
          className: 'justify-content-center align-items-center text-secondary',
          id: 'status'
        },
        {
          optionsParser: (item) => {
            return TableSelectorField(item, {
              thisService: getServiceById(item.serviceId),
              thisTopics: getTopicIdsInPlan(item.parts || item.fixedParts, item.kind),
              queryData: data,
              queryVar: query
            }, refetch)
          },
          colSpan: 2,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }
      ]

      return [
        { render: item => {
          return (
            <GroupStudents students={(item?.studentPlans || [])?.filter(({planStatus}) => planStatus !== 'cancel')?.map(item => getStudentById(item.studentId))} />
          )
        }, colSpan: 4, id: 'avatar' },
        { render: item => item.name, colSpan: 5, hover: (item) => item.name, type: 'tooltip', className: 'text-secondary', id: 'planName' },
        {
          render: (item) => {
            return <PlanDurationField parts={item.PartsSchedule} fixedParts={item.fixedParts} />
          },
          hover: (item) => {
            return <PlanDurationHover parts={item.PartsSchedule} fixedParts={item.fixedParts} />
          },
          colSpan: 2,
          type: 'tooltip',
          className: 'justify-content-center align-items-center text-secondary',
          id: 'duration'
        },
        {
          render: (item) => {
            const studentPlans = item?.studentPlans?.filter(({planStatus}) => planStatus !== 'cancel')
            const progress = (studentPlans || []).reduce((totalApprove, stu) => {
              return totalApprove + (stu.planStatus === 'scheduled' ? 1 : 0)
            }, 0)
            return <span className='w-100 text-center'>{`${((progress / studentPlans.length) * 100).toFixed(2)}% (${progress}/${studentPlans.length})`}</span>
          },
          hover: (item) => {
            return <ProgressGroupStudentHover item={{...item, studentPlans: item?.studentPlans?.filter(({planStatus}) => planStatus !== 'cancel')}} />
          },
          type: 'tooltip',
          colSpan: 2,
          className: 'justify-content-end align-items-center text-secondary',
          id: 'progress'
        },
        {
          render: (item) => {
            return <StatusField plan={item} status={item.status} />
          },
          hover: (item) => {
            return <ProgressGroupPlanHover item={item} isHover={item.isHover} />
          },
          type: 'tooltip',
          colSpan: 2,
          className: 'justify-content-end align-items-center text-secondary',
          id: 'status'
        },
        {
          optionsParser: (item) => {
            return TableSelectorField(item, {
              thisService: getServiceById(item.serviceId),
              thisTopics: getTopicIdsInPlan(item.parts || item.fixedParts, item.kind),
              queryData: data,
              queryVar: query
            }, refetch)
          },
          colSpan: 3,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }
      ]
    } else if (['GroupPLan'].includes(typename)) return []

    if (breakWidth === 'SM') {
      return [
        { render: (item) => <CompactPlanInfoField item={item} studentUser={item.studentUser} status={item.status} thisService={getServiceById(item.serviceId)} />, colSpan: 8, className: 'justify-content-start align-items-center', id: 'avatar' },
        {
          optionsParser: (item) => {
            return TableSelectorField(item, {
              thisService: getServiceById(item.serviceId),
              thisTopics: getTopicIdsInPlan(item.parts || item.fixedParts, item.kind),
              queryData: data,
              queryVar: query
            }, refetch)
          },
          colSpan: 2,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }
      ]
    } else if (breakWidth === 'MD' || breakWidth === 'LG') {
      return [
        { render: (item) => <CompactPlanInfoField item={item} studentUser={item.studentUser} status={item.status} thisService={getServiceById(item.serviceId)} />, colSpan: 4, className: 'justify-content-start align-items-center', id: 'avatar' },
        {
          render: (item) => {
            return <PlanDurationField parts={item.parts} fixedParts={item.fixedParts} />
          },
          hover: (item) => {
            return <PlanDurationHover parts={item.parts} fixedParts={item.fixedParts} />
          },
          colSpan: 2,
          type: 'tooltip',
          className: 'justify-content-center align-items-center text-secondary',
          id: 'duration'
        },
        {
          render: (item) => {
            return (
              moment.duration(moment().diff(moment(item.updatedAt))).humanize()
            )
          },
          hover: (item) => {
            return <Stats className='znk-stats-font-small' items={[]} />
          },
          type: 'tooltip',
          colSpan: 2,
          className: 'justify-content-center align-items-center text-secondary',
          id: 'progress'
        },
        {
          optionsParser: (item) => {
            return TableSelectorField(item, {
              thisService: getServiceById(item.serviceId),
              thisTopics: getTopicIdsInPlan(item.parts || item.fixedParts, item.kind),
              queryData: data,
              queryVar: query
            }, refetch)
          },
          colSpan: 2,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }
      ]
    }
    return [
      { render: (item) => {
        return <StudentField studentUser={item.studentUser} item={item} />
        }, colSpan: 3, className: 'justify-content-start align-items-center text-title', id: 'avatar' 
      },
      {
        render: (item) => {
          return item?.isAtomic ? (
            <img
              src={Atom}
              alt='atom'
              color='#7E8C9E'
              width='40'
              height='40'
            />
          ) : null;
        },
        colSpan: 2,
        className: 'justify-content-start align-items-center text-secondary',
        id: 'atomic'
      },
      {
        render: (item) => {
          return <ServiceField thisService={getServiceById(item.serviceId)} />
        },
        hover: (item) => {
          return <ServiceHover {...item} thisService={getServiceById(item.serviceId)} />
        },
        colSpan: 2,
        type: 'tooltip',
        className: 'justify-content-start align-items-center text-secondary',
        id: 'service'
      },
      {
        render: (item) => {
          return <TopicsField thisService={getServiceById(item.serviceId)} item={item} />
        },
        colSpan: 4,
        className: 'justify-content-start align-items-center text-secondary',
        id: 'topics'
      },
      {
        render: (item) => {
          return <PlanDurationField parts={item.parts} fixedParts={item.fixedParts} />
        },
        hover: (item) => {
          return <PlanDurationHover parts={item.parts} fixedParts={item.fixedParts} />
        },
        colSpan: 2,
        type: 'tooltip',
        className: 'justify-content-center align-items-center text-secondary',
        id: 'duration'
      },
      {
        render: (item) => {
          return <CostField item={item} />
        },
        colSpan: 2,
        className: 'justify-content-center align-items-center text-secondary',
        id: 'cost'
      },
      {
        render: (item) => {
          return (
            moment.duration(moment().diff(moment(item.updatedAt))).humanize()
          )
        },
        hover: (item) => {
          return <ProgressPlanHover item={item} isHover={item.isHover} />
        },
        type: 'tooltip',
        colSpan: 2,
        className: 'justify-content-center align-items-center text-secondary',
        id: `updatedAt`
      },
      {
        render: (item) => {
          return <StatusField plan={item} status={item.status} />
        },
        colSpan: 2,
        className: 'justify-content-end align-items-center text-secondary',
        id: 'status'
      },
      {
        optionsParser: (item) => {
          return TableSelectorField(item, {
            thisService: getServiceById(item.serviceId),
            thisTopics: getTopicIdsInPlan(item.parts || item.fixedParts, item.kind),
            queryData: data,
            queryVar: query
          }, refetch)
        },
        colSpan: 3,
        type: 'selector',
        className: 'justify-content-end align-items-center text-secondary',
        id: 'buttons'
      }
    ]
  }, [breakWidth, query, data, getServiceById, getStudentById, refetch, typename])
}

export default useResponsivePlanColumns

import React from 'react'
import {TabContent, TabPane} from 'reactstrap'
import { useParams, useHistory } from "react-router-dom"

import StudentDashboard from './Student'
import './index.css'
import NoAuthorize from '../../common/NoAuthorize'
import { useSelector } from 'react-redux'
import DashboardDashboard from './Dashboard'
import DashboardLiveprep from './LivePrep'
import DashboardEducators from './Educators'

const Sections = {
    students: 'students',
    kpi: 'kpi',
    educators: 'educators',
    counseling: 'counseling',
    liveprep: 'liveprep'
}

const KEY_PAGE = 'dashboard'
const DashboardPage = () => {
    let { section } = useParams()
    const history = useHistory()
    const {staffInfo} = useSelector((state) => state.user)
    const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes(KEY_PAGE)

    if (!isPermited) {
        return <NoAuthorize />
    }
    return (
        <div className={`col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`} style={{minHeight: '100%'}}>
            <div className={`d-flex flex-column m-0 p-0`}>
                <div className='container-fluid mt-2 mt-md-5 d-flex px-2 flex-column-reverse flex-md-row'>
                    <div className='d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0'>
                        <span onClick={() => {history.push(`/dashboard/${Sections.students}`)}} className={`nav-title ${section === Sections.students ? 'active' : ''} m-0 mr-3 cursor-pointer`}>Students</span>
                        {/* <span onClick={() => {}} className={`nav-title ${section === 'educators' ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Educators</span> */}
                        <span onClick={() => {history.push(`/dashboard/${Sections.educators}`)}} className={`nav-title ${section === Sections.educators ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Educator</span>
                        <span onClick={() => {history.push(`/dashboard/${Sections.kpi}`)}} className={`nav-title ${section === Sections.kpi ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Counseling</span> 
                        <span onClick={() => {history.push(`/dashboard/${Sections.liveprep}`)}} className={`nav-title ${section === Sections.liveprep ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Liveprep</span>
                    </div>
                    <div className='d-md-flex d-none align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
                        
                    </div>
                </div>
                <div className={`m-0 p-0`}>
                    <TabContent activeTab={false ? 'loading' : section}>
                        <TabPane tabId={`loading`}>
                            LOADING
                        </TabPane>
                        <TabPane tabId={`students`}>
                            <StudentDashboard/>
                        </TabPane>
                        <TabPane tabId={`educators`}>
                            <DashboardEducators/>
                        </TabPane>
                        <TabPane tabId={`kpi`}>
                            <DashboardDashboard/>
                        </TabPane>
                        <TabPane tabId={`liveprep`}>
                            <DashboardLiveprep/>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default DashboardPage
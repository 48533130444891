import React, {useEffect, useRef} from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';


const DashboardLiveprep = () => {
    const dashboardRef = useRef(null)
    const chartContentRef = useRef(null)
    useEffect(
        () => {
            const widthContent = chartContentRef?.current?.getBoundingClientRect()?.width
            const heightContent = chartContentRef?.current?.getBoundingClientRect()?.height

            const sdk = new ChartsEmbedSDK({
                baseUrl: 'https://charts.mongodb.com/charts-timeline-dinqz'
            });

            // embed a dashboard
            const dashboard = sdk.createDashboard({
                dashboardId: '7e777245-fed0-4b4a-9bf0-007521c43727',
                width: widthContent,
                height: heightContent
            });

            // render the chart into a container
            dashboard
            .render(dashboardRef.current)
            .catch((err) => {
                window.alert('Dashboard failed to initialise')
                console.warn(err)
            });
        }, []
    )

    return (
        <div className={`row w-100`} ref={chartContentRef} style={{height: '100vh'}}>
            <div ref={dashboardRef} id='dashboard'/>
        </div>
    )
}

export default DashboardLiveprep
import React, {useEffect, useState} from 'react'
import {getMessages, markAsReader} from '../../helpers/getMessages'
import {ChatBox} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import {Spinner} from 'reactstrap'
import NoHaveMessages from '../NoHaveMessages'

const message = {messages: [], error: null, loading: false}

const MessagesItems = (props) => {
  const {id: lessonID, __typename: type, usersList, isActive = false, onNewMessage = () => {}} = props
  const {id: userID, timezone} = useSelector((state) => state.user)
  const [messages, setMessages] = useState(message)
  const {loading, error} = messages
  const [msgWithRole, setMsgWithRole] = useState()
  useEffect(() => {
    getMessages(lessonID, setMessages)
    // markAsReader(lessonID, userID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonID])

  useEffect(() => {
    if (messages.messages.length > 0) {
      setMsgWithRole(
        messages?.messages.map((msg) => {
          const found = usersList?.find((user) => user.id === msg.userID)
          if (userID === msg.userID) return {...msg, role: 'You' }
          else return {...msg, role: found?.role?.charAt(0)?.toUpperCase() + found?.role?.slice(1) }
        }),
      )
    }
    // eslint-disable-next-line
  }, [messages, loading, usersList])
  

  useEffect(() => {
    // if(isActive) 
      onNewMessage(messages)
  }, 
  // eslint-disable-next-line
  [messages])
  
  useEffect(() => {
    if(isActive && userID) {
      markAsReader(lessonID, userID)
    }
    // eslint-disable-next-line
  }, [isActive, userID])

  return (
    <>
      {loading ? (
        <>
          {messages.messages.length === 0 ? (
            <NoHaveMessages {...props} />
          ) : (
              <ChatBox
                className={''}
                messages={msgWithRole}
                myUserId={userID}
                timezone={timezone}
                type={type}
              />
          )}
        </>
      ) : (
        <div className='text-center m-9'>
          <Spinner type='grow' color='dark' />
        </div>
      )}
      {error && 'Error to get messages.'}
    </>
  )
}

export default MessagesItems

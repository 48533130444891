import React, { useEffect, useState, useMemo } from 'react'
import {
  Form,
  ModalLayout,
  StickyButton,
  Button,
  IconArrowUp,
  DropDown,
  ConfirmModal,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import moment from 'moment'
import { totalLessonPart } from '../New/utils'
import PaymentSchedule from '../New/Components/PaymentScehdule'
import editGroupPlan from '../../../../../Model/Plan/Group/Edit'
import PlanStudent from '../New/Components/PlanStudents'
import GroupLessonSchedulingModalEdit from './groupLessonSchedulingModal'
import momentz from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import { parseLpwGroupPlan } from './util'
import createGroupPlan from '../../../../../Model/Plan/Group/Create'
import { showAlert } from '../../../../../common/Alert/util'
import { TYPEFIXED } from '../../../const'
import PricingPlan from '../New/Components/PricingPlan'
import PlanDetails from '../New/Components/PlanDetails'
import ScheduleDetails from '../New/Components/PlanScheduling'
import PlanEducators from '../New/Components/PlanEducators'
import WeeklySchedule from '../New/Components/WeeklySchedule'

const EditGroupPlan = (props) => {
  const { refetch, educators: educatorsBase } = props
  const modalData = useSelector((state) => state.modal.plan.editGroupModal)
  const localTime = useSelector((state) => state.timezone.zone)
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, planId, isDuplicate } = modalData
  const [confirm, setConfirm] = useState(false)
  const [razon, setRazon] = useState('')
  const limit = 0
  //SERVICIOS
  const { data: dataServices } = useQuery(
    gql`query($limit: Int) {
    paginatedServices(limit: $limit) {
      hasNext
      hasPrevious
      next
      previous
      totalDocs
      docs {
        id
        name
        topics {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }`, {
    variables: { limit: limit }
  })
  const [form, setForm] = useState({
    typePlan: 'date',
    idservice: '5f90ca14d24f26724bb85933',
    locationPlan: null,
    planname: '',
    typePlanPricing: 'perlesson',
    typeLesson: 'lessonPerWeek',
    lpw: [{ partname: 'Parte 1', startdate: new Date(), enddate: new Date(), typeLesson: 'lessonPerWeek' }],
    coutas: [],
    educatorsGroupFull: [],
    educatorsIndividualFull: [],
    excludeDays: [],
    payments: {
      registration: true,
      exam: false,
      pro: false,
      lessons: true,
      groupLessons: true,
      discount: {
        registration: 0,
        exam: 0,
        pro: 0,
        lessons: 0
      },
      values: {
        registration: 200,
        exam: 50,
        pro: 50
      }
    }
  })
  const { submit, loading: loadingEdit } = editGroupPlan(() => {
    refetch()
    toggle()
  }, console.log)


  //crear (duplicar) group plan
  const { submit: duplicateGroupPlan, loading: loadDuplicatePlan } = createGroupPlan((res) => {
    showAlert({
      status: 'success',
      text: "Successfully Duplicated Group Plan!"
    }, dispatch)
    refetch()
    toggle()
  }, console.log)


  const [mePlan, { data }] = useLazyQuery(
    gql`query($planId: ID!){
      getGroupPlan(planId: $planId) {
        id
        name
        cancelDate
        pauseDate
        resumeDate
        studentPlans {
          studentId
          planId
          planStatus
          registerDate
          firstName
          lastName
        }
        serviceId
        lessonIds
        paymentItemIds
        paymentItemsConfig {
          amount
          percentDiscount 
          amountWithoutDiscount
          date
          description
          type
        }
        recommendedEducators {
          educatorUserId
          firstName
          lastName
          rate
          isManualRate
          topicId
          teachingType
          lessonLink
          isWaiveIndividual
          isWaiveGroup
        }
        selectedEducators {
          educatorUserId
          firstName
          lastName
          rate
          isManualRate
          topicId
          lessonLink
          isWaiveIndividual
          isWaiveGroup
        }
        status
        kind
        createdAt
        updatedAt  
        isFirstPlan
        typePlanPricing
        pricingRate
        groupPricingRate
        location {
          type
          linkEducator
          linkStudent
        }
        groupId
        ... on GroupPLan {
          testDate
          PartsSchedule {
            name
            from
            to
            scheduleType
            lessonsPerWeekGroup {
              topicId
              educatorId
              day
              duration
              hour
              totalLessons
            }
            lessonsPerWeekIndividual {
              topicId
              lessonsPerWeek
              lockLessons
            }
          }
          studentPlans {
            studentId
            planId
            registerDate
            planStatus
          }
          lessonGroupIds
        },
        childPlans {
          id
          studentUser {
            studentUserId
            firstName
            lastName
            rate
            isManualRate
          }
          kind
          name
          status
          ... on LessonsPerWeekPlan {
            parts {
              from
              to
              totalLessons
              name
              lessonsPerWeek
              lessonPerWeekPerTopic {
                topicId
                lessonsPerWeek
                lockLessons
              }
            }
          }
          ... on LessonsFixedPlan {
            parts {
              from
              tof: to
              type
              TotalPerTopic {
                topicId
                lessonsPerWeek
                lockLessons
                numberFixedLessonsPerWeek
              }
            }
          }
        }
      }
    }`,
    {
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    if (planId && planId !== '') {
      (async () => {
        await mePlan({
          variables: { planId }
        })
      })()
    }
  }, [planId, mePlan])
  const getLinkRecommended = (educatorId, arr) => {
    if (!arr || arr === 0) {
      return ''
    }
    const base = arr.filter(element => element.educatorUserId === educatorId && element.teachingType === 'group')
    if (base.length === 0) {
      return ''
    }
    const link = base[0].lessonLink
    return link
  }
  const getLink = (educatorId) => {
    if (educatorsBase && educatorsBase.length === 0) {
      return ''
    }
    const base = educatorsBase.filter(element => element.id === educatorId)
    if (base.length === 0) {
      return ''
    }
    const link = base[0].educatorInfo ? base[0].educatorInfo.backupLink ? base[0].educatorInfo.backupLink : '' : ''
    return link
  }
  const [, setBaseForm] = useState({})
  useEffect(() => {
    const parsePlanToEdit = (plan) => {
      const namesPayments = {
        'Payment pro': { name: 'pro' },
        'Payment exam': { name: 'exam' },
        'Payment registration': { name: 'registration' }
      }
      // objeto payments original sin ningún campo checkeado
      let payments = {
        groupLessons: true,
        registration: false,
        exam: false,
        pro: false,
        lessons: false,
        discount: {
          registration: 0,
          exam: 0,
          pro: 0,
          lessons: 0
        },
        values: {
          registration: 200,
          exam: 50,
          pro: 50
        }
      }
      plan.paymentItemsConfig.forEach((pay) => {
        if (pay.type === 'others-fixed') {
          namesPayments[pay.description].used = true
          payments = {
            ...payments,
            [namesPayments[pay.description].name]: true,
            discount: {
              ...payments.discount,
              [namesPayments[pay.description].name]: pay.percentDiscount
            },
            values: {
              ...payments.values,
              [namesPayments[pay.description].name]: pay.amountWithoutDiscount
            }
          }
        } else {
          payments = {
            ...payments,
            lessons: true,
            discount: {
              ...payments.discount,
              lessons: pay.percentDiscount
            }
          }
        }
      }, {})
      const educators = plan.recommendedEducators.filter((elemet) => elemet.teachingType === 'individual').map((edu) => ({ ...edu, backupLink: edu.lessonLink || '' }))
      const groupEducators_ = []
      plan.PartsSchedule.forEach(
        (part, index) => {
          part.lessonsPerWeekGroup.forEach(
            topic => {
              const foundEducator = plan.recommendedEducators.find((elemet) => elemet.teachingType === 'group' && elemet.educatorUserId === topic.educatorId && elemet.topicId === topic.topicId)
              if (!groupEducators_.find(e => e.partIndex === index && e.teachingType === 'group' && e.educatorUserId === topic.educatorId && e.topicId === topic.topicId)) {
                groupEducators_.push({
                  partIndex: index,
                  ...foundEducator,
                  backupLink: foundEducator.lessonLink
                })
              }
            }
          )
        }
      )
      const planOut = {
        planId: plan.id,
        groupId: plan.groupId,
        planname: plan.name,
        amount: plan.pricingRate,
        groupAmount: plan.groupPricingRate,
        testdate: {
          name: `${dataServices && dataServices?.paginatedServices?.docs?.filter((serv) => serv.id === plan.serviceId)[0]?.name} ${moment.utc(plan.testDate).format('MMM Do, YYYY')}`,
          date: plan.testDate
        },
        typePlan: plan.testDate ? 'date' : 'time',
        educatorsIndividualFull: educators,
        educatorsGroupFull: groupEducators_,
        educatorsIndividual: plan.recommendedEducators.filter((elemet) => elemet.teachingType === 'individual').reduce((allEdu, edu) => { return { ...allEdu, [edu.topicId]: { ...edu, id: edu.educatorUserId, backupLink: getLink(edu.educatorUserId) } } }
          , {}),
        educatorsGroup: plan.recommendedEducators.filter((elemet) => elemet.teachingType === 'group').reduce((allEdu, edu) => { return { ...allEdu, [edu.topicId]: { ...edu, id: edu.educatorUserId, backupLink: getLinkRecommended(edu.educatorUserId, plan.recommendedEducators) } } }
          , {}),
        idservice: plan.serviceId,
        statusPlan: plan.status,
        students: plan.studentPlans,
        typeLesson: plan.PartsSchedule.length > 0 ? plan.PartsSchedule[0].scheduleType : null,
        paymentItemsConfig: plan?.paymentItemsConfig || [],
        defaultPaymentItemsConfig: plan?.paymentItemsConfig || [],
        paymentstart: plan.paymentItemsConfig ? plan.paymentItemsConfig.filter((obj) => obj.type === 'lessons-payment')[0].date.split('T')[0] : '',
        lpw: parseLpwGroupPlan(plan, localTime),
        isFirstPlan: plan.isFirstPlan,
        typePlanPricing: plan.typePlanPricing,
        payments: payments,
        locationPlan: plan.location.type,
        locationPlanLink: plan.location.linkEducator || null,
        PartsSchedule: plan.PartsSchedule,
        childPlans: plan.childPlans,
        cuotas: plan.paymentItemsConfig.filter(e => e.type !== 'others-fixed').map(ele => {
          return {
            custom: false,
            time: moment(ele.date),
            value: ele.amount
          }
        }),


        excludeDays: plan?.excludeDays || [],
      }
      setBaseForm(planOut)
      return planOut
    }
    if (data) {
      let parseData = parsePlanToEdit(data.getGroupPlan)
      if (isDuplicate) parseData = { ...parseData, students: [] }
      setForm(parseData)
    }
    // eslint-disable-next-line
  }, [data, localTime, isDuplicate])

  const isEdit = true
  const toggle = () => {
    setConfirm(false)
    setForm({
      typePlan: 'date',
      idservice: null,
      locationPlan: null,
      planname: '',
      typePlanPricing: '',
      lpw: [{ partname: 'Parte 1', startdate: new Date(), enddate: new Date(), typeLesson: 'lessonPerWeek' }],
      educatorsGroupFull: [],
      educatorsIndividualFull: [],
      coutas: [],
      payments: {
        registration: true,
        exam: false,
        pro: false,
        lessons: true,
        groupLessons: true,
        discount: {
          registration: 0,
          exam: 0,
          pro: 0,
          lessons: 0
        },
        values: {
          registration: 200,
          exam: 50,
          pro: 50
        }
      }
    })
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.editGroupModal',
      data: { isOpen: !isOpen }
    })
  }
  const currentService = dataServices ? dataServices.paginatedServices.docs && dataServices.paginatedServices.docs.find((serv) => serv.id === form.idservice) : null
  let start = form.from
  let end = form.to
  // let payments = []
  if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
    // payments = getFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
  } else if (start !== null && end !== null) {
    start = moment(start)
    end = moment(end)
    // payments = getFeeFromRangeDate(start, end)
  }
  const onSubmit = (status) => {
    if (status === '') {
      return
    }
    const discount = parseFloat(form.payments.discount.lessons || 0)
    const fee = paymentItemConfig.filter(({ atSigning }) => !atSigning).map((cuota, index) => {
      const awd = (parseFloat(cuota.amount)/(100-discount))*100
      return {
        amount: cuota.amount,// * (1 - (parseFloat(form.payments.discount.lessons || 0) / 100)),
        date: cuota.date,
        description: `Payment ${index}`,
        type: 'lessonsPayment',
        percentDiscount: discount,
        amountWithoutDiscount: cuota?.amountWithoutDiscount || awd
      }
    })
    const otherFixed = [
      { key: 'registration', value: 200 },
      { key: 'exam', value: 50 },
      { key: 'pro', value: 50 }
    ]
    let auxForm = form
    if (auxForm.typePlan === 'time' && auxForm.typeLesson === 'totalLesson') {
      // setea enddate a cada parte
      auxForm = {
        ...auxForm,
        lpw: Object.keys(auxForm.lpw).reduce((obj, index) => {
          return { ...obj, [index]: { ...auxForm.lpw[index], enddate: auxForm.lpw[index].startdate } }
        }, {})
      }
    }
    otherFixed.forEach((obj) => {
      if (auxForm.payments[obj.key]) {
        fee.push({
          amount: obj.value * (1 - (parseFloat(auxForm.payments.discount[obj.key] || 0) / 100)),
          date: moment(),
          description: `Payment ${obj.key}`,
          type: 'otherFixed',
          percentDiscount: parseFloat(auxForm.payments.discount[obj.key] || 0),
          amountWithoutDiscount: parseFloat(obj.value),
          typeFixed: TYPEFIXED[obj.key] || TYPEFIXED.nothing
        })
      }
    })
    fee.forEach(ele => {
      ele.amountWithoutDiscount = parseFloat(ele.amountWithoutDiscount)
    })
    const partsSend = Object.entries(form.lpw).reduce((arr, [index, part]) => {
      const { partname, startdate, enddate, ...topics } = part
      const lessonsPerWeekGroup = []
      const lessonsPerWeekIndividual = []
      Object.entries(topics).forEach(([topicId, info]) => {
        if (info.valueGroup && info.groupBlocks) {
          info.groupBlocks.forEach(block => {
            lessonsPerWeekGroup.push({
              topicId,
              educatorId: form.educatorsGroupFull.find(ele => ele.topicId === topicId.toString() && ele.partIndex === parseInt(index)).educatorUserId, // form.educatorsGroup[topicId].id,
              duration: 50,
              ...block.val,
              hour: `${block.val.hour}:00`
            })
          })
        }
        if (info.valueIndividual) {
          lessonsPerWeekIndividual.push({
            topicId,
            lessonsPerWeek: info.valueIndividual,
            lockLessons: form.typePlan === 'date' ? !!info.lock : false
          })
        }
      })
      const totalLessons = totalLessonPart(part, (form.typePlan === 'time' && form.typeLesson === 'totalLesson') ? 'fixed' : 'perWeek')
      return arr.concat([{
        name: partname,
        from: startdate,
        to: form.typeLesson === 'totalLesson' ? startdate : enddate,
        totalLessons,
        scheduleType: form.typeLesson || 'lessonPerWeek',
        lessonsPerWeekGroup,
        lessonsPerWeekIndividual
      }])
    }, [])
    let recommendedEducators = form.educatorsIndividualFull.map(element => {
      return {
        educatorUserId: element.educatorUserId,
        topicId: element.topicId,
        rate: element.rate && element.rate !== '' ? (typeof element.rate === 'string' ? parseInt(element.rate) : element.rate) : 1,
        isLockEducator: false,
        lessonLink: ['zoom', 'inPerson'].includes(form.locationPlan) ? element.backupLink : '',
        teachingType: 'individual',
        isWaiveIndividual: element.isWaiveIndividual,
        isWaiveGroup: undefined
      }
    })
    recommendedEducators = recommendedEducators.concat(form.educatorsGroupFull.map(element => {
      return {
        educatorUserId: element.educatorUserId,
        topicId: element.topicId,
        rate: element.rate && element.rate !== '' ? (typeof element.rate === 'string' ? parseInt(element.rate) : element.rate) : 1,
        isLockEducator: false,
        lessonLink: ['zoom', 'inPerson'].includes(form.locationPlan) ? element.backupLink : '',
        teachingType: 'group',
        isWaiveGroup: element.isWaiveGroup,
        isWaiveIndividual: undefined
      }
    })
    )

    const newVal = {
      groupId: form.planId,
      name: form.planname || `${currentService.name} Plan`,
      serviceId: form.idservice,
      recommendedEducators,
      enforceRecommendedEducators: false,
      testDate: form.testdate?.date,
      statusPlan: status,
      parts: partsSend,
      paymentItemsConfig: fee,
      studentPlans: form.students.map(stu => ({
        studentId: stu.__typename === 'User' ? stu.id : stu.studentId,
        planStatus: stu.planStatus ? stu.planStatus : undefined,
        //Valor individual por lección
        rate: form.typePlanPricing === "perlesson" ? form.amount || 75 : stu?.defaultRate || stu?.rate || 75
      })),
      location: {
        type: form.locationPlan,
        linkStudent: form.locationPlanLink || '',
        linkEducator: form.locationPlanLink || ''
      },
      typePlanPricing: form.typePlanPricing,
      individualRate: form.amount,
      groupRate: form.groupAmount ? form.groupAmount : 0,

      excludeDays: form?.excludeDays || [],
    }

    const partsModified = newVal.parts
    newVal.parts.forEach((item, index) => {
      const from = partsModified[index].from
      item.lessonsPerWeekGroup.forEach((element, idx) => {
        // const ndate = momentz(from).tz(getTimeZone(element.educatorId, element.topicId)).day(element.day).hour(parseInt(element.hour.split(':')[0])).format()
        const ndate = momentz(from).tz(localTime).day(element.day).hour(parseInt(element.hour.split(':')[0])).format()
        // const nh = momentTime(ndate).toISOString()
        const nh = moment.utc(ndate)
        partsModified[index].lessonsPerWeekGroup[idx].day = nh.day()
        partsModified[index].lessonsPerWeekGroup[idx].hour = nh.format('HH:mm')
      })
    })
    newVal.parts = partsModified
    setConfirm(false)
    if (isDuplicate) {
      //ejecutar mutación para crear plan grupal
      duplicateGroupPlan(newVal)
    } else {
      submit(newVal)
    }
  }
  const onPreSubmit = (value) => {
    setRazon(value)
    setConfirm(true)
  }
  const canSubmit = useMemo(() => {
    if (!form.idservice || form.idservice === '0') {
      return true
    }
    if (!form.testdate) {
      return true
    }
    if (!form.locationPlan) {
      return true
    }
    let pass = false
    const topicsWithIndividualEducators = form.educatorsIndividualFull.reduce((arr, edu) => arr.includes(edu.topicId) ? arr : arr.concat(edu.topicId), [])
    Object.values(form.lpw).forEach(
      (part, idx) => {
        const { partname, startdate, enddate, ...topics } = part
        if (part.partname === '' || !part.startdate || !part.enddate) {
          pass = true
        } else {
          if (Object.entries(topics).length) {
            let hasNoLessons = true
            Object.entries(topics).forEach(([topicId, info]) => {
              if (info.valueGroup || info.valueIndividual) {
                hasNoLessons = false
              }
              if (info.valueGroup && info.groupBlocks && info.valueGroup !== info.groupBlocks.length) {
                // value and blocks dont match
                pass = true
                return
              }
              if (info.groupBlocks && info.groupBlocks.length) {
                const blocksEducator = form.educatorsGroupFull.find(ele => ele.topicId === topicId.toString() && ele.partIndex === idx)
                if (!blocksEducator) {
                  // No Educator for this part
                  pass = true
                  return
                }
              }
              if (info.valueIndividual && !topicsWithIndividualEducators.includes(topicId)) {
                // no educator for individual topic
                pass = true
              }
            })
            if (hasNoLessons) {
              // has no lessons this part
              pass = true
            }
          } else {
            // no tiene ningún topico seteado
            pass = true
          }
        }
      }
    )
    if (!form.students || !form.students.length === 0) {
      return true
    }
    if (form.typePlanPricing === 'perlesson' && (typeof form.amount != 'number' || typeof form.groupAmount != 'number')) {
      return true
    }
    if (form.typePlanPricing === 'permonth' && (typeof form.amount != 'number')) {
      return true
    }
    if (pass) {
      return true
    }
    return false
    // eslint-disable-next-line
  }, [
    form
  ])
  // const { open: openReviewScheduledModal, close: closeReviewScheduledModal, reviewProps } = useReviewScheduledModal()
  const [paymentItemConfig, setPaymentItemsConfig] = useState([])
  const atLestOneScheduled = useMemo(
    () => {
      return (data?.getGroupPlan?.childPlans || [])?.map(({ status }) => status).includes("scheduled")
    }, [data]
  )

  return (
    <>
      {
        confirm && (
          <ConfirmModal
            className='m-4'
            toggle={toggle}
            isOpen={isOpen}
            title={translate('plans.modal.editplan.confirm')}
            body={translate('plans.modal.editplan.bodyconfirm')}
            confirmAction={() => {
              onSubmit(razon)
            }}
            cancelAction={toggle}
            confirmText={translate('plans.modal.cancelplan.confirm')}
            cancelText={translate('plans.modal.cancelplan.cancel')}
          />
        )
      }
      <ModalLayout
        className='modal-sidebar modal-colored scheduled-modal modal-desktop-half'
        isOpen={isOpen}
        toggle={toggle}
        topHead={
          <div className='p-0 m-0'>
            <span className='h2 font-weight-bold flex-grow-1 test-truncate'>
              {
                isDuplicate ? "Duplicate Group Plan" : translate('plans.modal.editGroupPlan')
              }
            </span>
            {/* {
              loadModal && <IconLoad/>
            } */}
          </div>
        }
        minHead={
          <div className='p-0 m-0'>
            <span className='h2 font-weight-bold flex-grow-1 test-truncate'>
              {
                isDuplicate ? "Duplicate Group Plan" : translate('plans.modal.editGroupPlan')
              }
            </span>
            {/* {
              loadModal && <IconLoad/>
            } */}
          </div>
        }
      >
        <TabContent activeTab={data && currentService ? 'show' : 'loading'}>
          <TabPane tabId={`loading`}>
            <div className='w-100 px-2 d-flex flex-column'>
              {
                new Array(10).fill(0).map(
                  () => {
                    return (
                      <>
                        <Skeleton count={1} height={12} style={{borderRadius: '12px'}} className={`mb-1 w-30`} />
                        <Skeleton count={1} height={40} style={{borderRadius: '12px'}} className={`mb-1`} />
                      </>
                    )
                  }
                )
              }
            </div>
          </TabPane>
          <TabPane tabId={`show`}>
            {
              data && currentService && (
                <Form value={form} onChange={setForm}>
                  <div className='w-100 px-2'> {/** px-4 px-sm-6 px-md-7**/}
                    {/* Plan Details */}
                    <PlanDetails disabled={atLestOneScheduled} form={form} setForm={setForm} data={dataServices?.paginatedServices || { docs: [] }} isEdit />
                    <div className={`w-100 borderTop my-2`} />
                    {/* Schedule Details */}
                    <ScheduleDetails disabled={atLestOneScheduled} form={form} setForm={setForm} currentService={currentService} />
                    <div className={`w-100 borderTop my-2`} />
                    {/* Plan Educators */}
                    <PlanEducators disabled={atLestOneScheduled} form={form} setForm={setForm} currentService={currentService} educatorsBase={educatorsBase} multiple />
                    <div className={`w-100 borderTop my-2`} />
                    {/* Weekly Scheduled */}
                    <WeeklySchedule where disabled={atLestOneScheduled} form={form} setForm={setForm} currentService={currentService} educatorsBase={educatorsBase} />
                    <div className={`w-100 borderTop my-2`} />
                    <PlanStudent disabled={false} form={form} setForm={setForm} />
                    <div className={`w-100 borderTop my-2`} />
                    <PricingPlan disabled={atLestOneScheduled} isEdit form={form} setForm={setForm} />
                    <div className={`w-100 borderTop my-2`} />
                    {/* Payment Schedule*/}
                    <PaymentSchedule
                      // setLoading={setLoadModal}
                      disabled={atLestOneScheduled}
                      form={form}
                      // baseForm={baseForm}
                      onChange={(newPayments) => {
                        setPaymentItemsConfig(newPayments)
                      }}
                    />
                    <StickyButton>
                      <div className='m-0 p-0 row'>
                        <Button
                          color='primary'
                          activity={loadingEdit || loadDuplicatePlan}
                          className={
                            isEdit ? 'col-12' : 'col-9'
                          }
                          label={isDuplicate ? 'Duplicate Group Plan' : isEdit ? translate('plans.modal.save') : translate('plans.modal.saveindraft')}
                          onClick={() => {
                            onPreSubmit('draft')
                          }}
                          style={{ borderRadius: `${isEdit ? '16px' : '16px 0px 0px 16px'}` }}
                          disabled={canSubmit}
                        />
                        {
                          !isEdit &&
                          <Button
                            color='primary'
                            className='col-2'
                            activity={loadingEdit || loadDuplicatePlan}
                            disabled={canSubmit}
                            label={
                              <DropDown
                                direction='up'
                                hoverEnabled={false}
                                classNameMenu='w-auto mb-3'
                                options={[{
                                  component:
                                    <h4
                                      className='p-3'
                                      onClick={() => {
                                        onPreSubmit('sent')
                                      }}
                                    >{translate('plans.modal.saveinsent')}
                                    </h4>
                                }]}
                              >
                                <IconArrowUp />
                              </DropDown>
                            }
                            style={{ borderRadius: '0px 16px 16px 0px', marginLeft: '1px' }}
                          />
                        }
                      </div>
                    </StickyButton>
                  </div>
                </Form>
              )
            }
          </TabPane>
        </TabContent>
      </ModalLayout>
      <GroupLessonSchedulingModalEdit setForm={setForm} form={form} />
    </>
  )
}

export default EditGroupPlan

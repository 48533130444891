import { Button, IconCheckmark, IconClose, useWindowDimensions, IconLink , ListItem, Activity } from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'
import PaymentMethod from './PaymentMethod'
import AddCard from './AddCard'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import AddBankDebit from './AddBankDebit'
import AddManualPaymentMethod from './AddManualPaymentMethod'
import useGetLinkRequestPM from '../../useQueries/useGetRequestPaymentMethodLink'
import useCopyText from '../../../../../../common/CopyText/useCopyText'
import { showAlert } from '../../../../../../common/Alert/util'
import AddZellePaymentMethod from './AddZellePaymentMethod'
import SearchByStripeId from './SearchByStripeId'
import CopyStripeId from './CopyStripeId'

const ChangePaymentMethod = props => {
  const { toggle, userData, refetch: refetchFull, isParent = false } = props
  const [page, setPage] = useState('methods')
  const [linkPaymentMethod, setLinkPaymentMethod] = useState('')

  const [copyText, setCopyText] = useCopyText()
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  //hook para utilizar modal que mueve métodos de pago de un usuario a otro
  // const { open: openTransportCostumer, close: closeTransportCostumer } = useTransportCostumer()

  const [
    requestPMLinkQuery,
    { data: dataPMLinkQuery, loading: loadPMLinkQuery },
  ] = useGetLinkRequestPM()

  useEffect(() => {
    if (dataPMLinkQuery) {
      setLinkPaymentMethod(dataPMLinkQuery.getLinkRequestPaymentMethod)
    }
  }, [dataPMLinkQuery])

  const [copySuccess, copyError] = [
    (text) => {
      //success
      showAlert({ text: translate("modal.infolesson.copy"), status: 'success' }, dispatch)
    },
    (error, text) => {
      //error
      showAlert({ text: `${translate("modal.infolesson.copyError")} \n ${copyText}`, status: 'error' }, dispatch)
    }
  ]

  useEffect(() => {
    if (linkPaymentMethod !== '') {
      setCopyText(linkPaymentMethod, copySuccess, copyError)
    }
    // eslint-disable-next-line
  }, [linkPaymentMethod])

  const currentMethod = userData ? userData.paymentMethod : null
  const GET_USER_LIST = gql`
    query {
      getPlaidLink
      me {
        id
        paymentMethod {
          kind
          ... on CardPaymentMethod {
            cardInfo {
              last4
              brand
              expYear
              expMonth
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
        registeredPaymentMethods {
          kind
          ... on CardPaymentMethod {
            cardInfo {
              last4
              brand
              expYear
              expMonth
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
      }
    }
  `
  const { breakWidth } = useWindowDimensions()
  // eslint-disable-next-line
  const { data, loading: methodLoading, refetch: refetchUserMethods } = useQuery(GET_USER_LIST)
  const registeredMethods =
    userData ? userData.registeredPaymentMethods : []

    
  const [setDefaultMethod, { loading: loadingDefaultMethod }] = useMutation(
    gql`
      mutation setUserDefaultPaymentMethod($userId:String!, $paymentMethodId: String!) {
        setUserDefaultPaymentMethod(userId: $userId, paymentMethodId: $paymentMethodId) {
          id
          paymentMethod {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
          }
          registeredPaymentMethods {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              zellePaymentInfo {
                emailToSend
              }
            }
          }
        }
      }
    `,
    {
      update: (cache, mutationResult) => {
        cache.writeQuery({
          query: GET_USER_LIST,
          data: {
            getPlaidLink: data.getPlaidLink,
            me: mutationResult.data.setMyDefaultPaymentMethod,
          },
        })
      },
    },
  )
  const setMethodAsDefault = (paymentMethodId) => {
    setDefaultMethod({
      variables: {
        userId: userData ? userData.id : null,
        paymentMethodId,
      },
    })
  }

  return (
    <div className={`w-100 py-5 ${breakWidth === 'SM' ? 'px-2' : 'px-8'}`}>
      {!isParent && <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>
          {(() => {
            switch (page) {
              case 'methods':
                return !userData
                  ? '...'
                  : currentMethod
                    ? translate('profile.actions.changeMethod.change')
                    : translate('profile.actions.changeMethod.add')
              case 'addCard':
                return translate('profile.actions.changeMethod.add')
              default:
                return null
            }
          })()}
        </h2>
        <IconClose size={20} onClick={toggle} color='rgba(119, 134, 153, .4)' />
      </span>}
      <div className={`container-fluid flex-column my-2 ${breakWidth === 'SM' ? 'px-0' : ''}`}>
        <TabContent className='py-auto' activeTab={page}>
          <TabPane tabId='methods'>
            <div className='container-fluid'>
              <div className='col-12 col-lg-12'>
                {
                  userData?.stripeId ? 
                  <CopyStripeId stripeId={userData.stripeId}  /> :
                  <SearchByStripeId refetch={refetchFull} userId={userData?.id} />
                }
        
                <div className='w-100 mb-4 pt-6'>
                  {userData ? (
                    <span className='text-title h3'>
                      {translate('profile.actions.changeMethod.current')}:
                    </span>
                  ) : (
                    <Skeleton height={60} />
                  )}
                </div>
                {userData && !methodLoading ? (
                  currentMethod !== null ? (
                    <PaymentMethod {...currentMethod} />
                  ) : (
                    <div className='flex-grow-1 p-auto'>
                      {translate('misc.notFound')}
                    </div>
                  )
                ) : (
                  <Skeleton height={60} />
                )}
              </div>
            </div>
            <div className='container-fluid mt-6'>
              <div className='col-12 col-lg-12 m-auto'>
                <div className='w-100 mb-4 d-flex flex-column justify-content-between'>
                  <span className='text-title h3'>
                    {translate('profile.actions.changeMethod.registered')}:
                  </span>
                  <span className={'m-0 p-0 d-flex flex-row w-100 justify-content-end'}>
                    <span
                      className='btn-link'
                      onClick={() => setPage('addCard')}
                    >
                      {translate('profile.actions.changeMethod.addCard')}
                    </span>
                    <span className={'text-title mx-2'}>/</span>
                    <AddManualPaymentMethod userId={userData?.id} refetch={refetchFull} />
                    {data && !methodLoading ? (
                      <>
                        <span className={'text-title mx-2'}>/</span>
                        <AddBankDebit
                          refetch={refetchFull}
                          keyPlaid={data.getPlaidLink}
                        />
                      </>
                    ) : null}
                    <span className={'text-title mx-2'}>/</span>
                    <AddZellePaymentMethod userId={userData?.id} refetch={refetchFull} />
                  </span>
                </div>
                {!userData || methodLoading ? (
                  <Skeleton height={60} count={3} />
                ) : registeredMethods?.length ? (
                  registeredMethods?.map((method, idx) =>
                    method.id !== currentMethod?.id ? (
                      <PaymentMethod
                        key={method.id || idx}
                        {...method}
                        clickable
                        mutation={setMethodAsDefault}
                        loading={loadingDefaultMethod}
                      />
                    ) : null,
                  )
                ) : (
                  translate('profile.actions.changeMethod.noRegisteredMethods')
                )}
              </div>
              {/* <div className={`my-2`}>
                <span className={`h45 btn-link`} onClick={() => {
                  openTransportCostumer(() => {
                    refetchUserMethods()
                    refetchFull()
                    closeTransportCostumer()
                  }, userData?.id)
                }}>Transport payment method</span>
              </div> */}
            </div>
          </TabPane>
          <TabPane tabId='addCard'>
            <AddCard
              onSuccess={() => {
                refetchFull()
                setPage('success')
              }}
              goBack={() => setPage('methods')}
              currenMethod={currentMethod}
              userId={userData ? userData.id : null}
            />
          </TabPane>
          <TabPane tabId='success'>
            <IconCheckmark size={breakWidth === 'SM' ? 80 : 120} />
            <h2 className='htext mb-8' style={{ marginTop: 48 }}>
              {translate('profile.actions.changeMethod.method')}{' '}
              {currentMethod === null
                ? translate('profile.actions.changeMethod.added')
                : translate('profile.actions.changeMethod.changed')}
            </h2>
            <p className='text-p mb-8'>
              {translate('profile.actions.changeMethod.success')}
            </p>
            <Button
              label={translate('misc.coolThanks')}
              color='primary'
              check
              size='medium'
              onClick={() => setPage('methods')}
              style={{ width: '100%' }}
            />
          </TabPane>
        </TabContent>
      </div>
      <div className='container-fluid'>
              <div className='col-12 col-lg-12'>
                <div className='w-100 mb-4 pt-2 px-3'>
      {userData ? (
                  <ListItem
                  className='mx-2 px-4'
                  hasHover={false}
                  showTopLine
                  showBottomLine
                  onClick={() => {
                    !loadPMLinkQuery && requestPMLinkQuery({
                      variables: {
                        studentEmail: userData?.emails[0].address
                      }
                    })
                  }}
                  Left={loadPMLinkQuery ? <Activity /> : <span className='text-gray'>Request Payment Method</span>}
                  Right={
                    <div className='d-flex align-items-center hover-icon'>
                            <IconLink size={16} color={'gray'}/>               
                    </div>
                  }
                /> 
      ) : (
        <div className='w-70 mx-auto mt-3'>

          <Skeleton height={50}/>
        </div>
      )
      }
      </div>
      </div>
      </div>
    </div>
  )
}

export default ChangePaymentMethod
import React, {useEffect, useMemo, useState, useRef} from 'react'
import {
    Table,
    // IconStar,
    Button,
    IconSearch,
    DropDownNew,
    DropDownSub,
    IconFilter,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import Pagination from '../../common/Pagination'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    TabPane, TabContent,
    Badge
  } from 'reactstrap'
import {useQuery, gql} from '@apollo/client'
import PlanTableSkeleton from '../Plans/Skeleton'
import useResponsivePlanColumns from './Columns/useResponsivePlanColumns'
import useNewPlanModal from './Modal/newPlanModal/useNewPlanModal'
import useViewPlanModal from './Modal/viewPlanModal/useViewPlanModal'
import useCounselingPlanReducer from '../../reducers/counseling-plan'
import usePlans from './Model/usePlans'
import StatusesFilter from '../Plans/Filters/Statuses'
import TypesFilter from './Filters/Types'
import GraduationFilter from './Filters/Graduation'
import CountryFilter from './Filters/Country'
import { orderClassOf } from './Utils/orderClassOf'

import useCounselingPlanList from './useCounselingPlanList'
//MAILS LIST
import useModalEmailsList from '../../common/ModalEmailsList/useModalEmailsList'
import useExcelDownload from '../../common/ExcelDownload/useExcelDownload'
import ExcelDownloadComponent from '../../common/ExcelDownload'
import OptionsEmailsAndDownloads from '../../common/ModalEmailsList/Components/Options'
import { useSelector } from 'react-redux'
import NoAuthorize from '../../common/NoAuthorize'
import useTranslation from '../../i18n/useTranslation'

import StartDateFilter from '../Plans/Filters/StartDate'
import ModalFilterMobile from './Filters/ModalFilterMobile'

const baseTypes = ['undergraduate', 'graduate', 'transfer', 'highSchool']
let arrayGraduationDates = []
// let countriesList = []
const baseStatuses = [
    'draft', 'sent',
    'cancel', 
    {
        id: 'inProgress', 
        multi: true, ids: ['scheduled', 'application', 'postApplication'], name: 'In Progress'
    }, //className: 'text-gray d-flex flex-row justify-content-start pl-1 mt-1 mb-2'},
    {id: 'scheduled', name: 'Pre Application', statusColor: 'warning-alt', className: `pl-6`}, 
    {id: 'application', name: 'Application', statusColor: 'inProgress', className: `pl-6`}, 
    {id: 'postApplication', name: 'Post Application', statusColor: 'primary-alt', className: `pl-6`}, 
    {id: 'completed', name: 'Completed', statusColor: 'success-alt'}
]
const CounselingPlans = () => {
    const { staffInfo } = useSelector((state) => state.user)
    const isPermited  = staffInfo?.permissions?.filter(e => e.isPermited)?.map(per => {return per.tag})?.includes('counseling-plans');
    const { translate } = useTranslation()
    const {breakWidth} = useWindowDimensions()
    const {open: openNewPlanModal, /**setOpenToEdit**/} = useNewPlanModal()
    const {open: openViewPlanModal} = useViewPlanModal()
    const [loadingExcelInfo, setLoadingExcelInfo] = useState(false)
    // const [getDataUsers, {loading: loadingUsersExcel}] = useDownLoadExcelUsers()
    // const {open: openViewPlanModal} = useViewPlanModal()
    const {parsePlanFromAPI} = useCounselingPlanReducer()
    //objeto con filtros aplicados a listado de planes
    const [filters, setFilters] = useState({
        //pagination
        limit: 10,
        previous: null,
        next: null,
        //filters
        search: '',
        kind: ["counceling"],
        status: baseStatuses
        //descarte labels
        .filter((item) => typeof item === 'string' || (typeof item !== 'string' && !!item?.id && !item?.multi))
        .map((item) => item?.id || item)
        .filter((status) => !['cancel', 'completed']?.includes(status)),
        type: baseTypes,
        classOf: [],
        countryIds: [],
        //shortcuts
        finishSoon: false,
        pendingApproval: false,
        lastApproved: false,
        previouslySent: false,
        shortRecenlySent: false,
        allPlans: true
    }) 

    
    const setShortCut = (shortcut) => {
        let auxFilter = {
            ...filters, 
            next: null,
            previous: null,
           //shortcuts
            finishSoon: false,
            pendingApproval: false,
            lastApproved: false,
            previouslySent: false,
            shortRecenlySent: false,
            allPlans: false
        }
        auxFilter[shortcut] = true
        setCurrentPage(1)
        setFilters(auxFilter)
    }

    //obtiene listado de planes desde API
    const {plans, refetch: refetchPlanList, paginatedData, loading} = usePlans(filters, {fetchPolicy: 'cache-first'})
    const {setRefetch} = useCounselingPlanList()
    // Modal lista de mails
    const { open: openEmailsListModal } = useModalEmailsList()
    const { open: openExcelDownloadModal } = useExcelDownload()
    const [execDownload, setExecDownload] = useState(false)
    //setea refetchPlanList en redux para ser ejecutado desde cualquier archivo
    useEffect(() => {
        if(refetchPlanList) {
            setRefetch(() => {refetchPlanList()})
        }
        // eslint-disable-next-line
    }, [refetchPlanList])

    //vuelve a ejecutar query
    useEffect(() => {
        //quita el shortcut `allPlans`
        const {allPlans, ...realFilters} = filters
        refetchPlanList(realFilters)
    }, [filters, refetchPlanList])
    
    //parsea listado de planes al formato del reduce (counselingPlanReduce)
    const planList = useMemo(() => {
        if( plans?.length > 0 ) return plans.map((plan) => {
            arrayGraduationDates = [...new Set(orderClassOf(plan.aditionalInfo?.applicationYear, arrayGraduationDates))]
            return parsePlanFromAPI(plan)})
        return []
    }, [plans, parsePlanFromAPI])

    const {data: dataCountries} = useQuery(gql`query{getAllCouncelingCountries{id name}}`)
    const countriesList = useMemo(() => dataCountries?.getAllCouncelingCountries || [], [dataCountries])

    const executeOnClickDownload = () => {
        openExcelDownloadModal(() => { }, "counselingPlan", filters)
        setExecDownload(true)
    }
    // const columns = useResponsivePlanColumns(planList, () => {
    //     refetchPlanList()
    // }, true)
    const columnsToTable = useResponsivePlanColumns(planList, () => {
        refetchPlanList()
    })
    const [currentPage, setCurrentPage] = useState(1)

    const searchInput = useRef(null) 
    if (!isPermited) {
        return <NoAuthorize />
    }     
    return (    
        <div className={`col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`} style={{minHeight: '100%'}}>
            <div className={`d-flex flex-column m-0 p-0`}>
                <div className='container-fluid mt-2 mt-md-5 d-flex px-2 flex-column-reverse flex-md-row'>
                    <div className='d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0'>
                        {/* SHORTCUTS */}
                        <span onClick={() => {setShortCut('allPlans')}} className={`nav-title ${filters['allPlans'] ? 'active' : ''} m-0 mr-3 cursor-pointer`}>All plans</span>
                        <span onClick={() => {setShortCut('pendingApproval')}} className={`nav-title ${filters['pendingApproval'] ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Pending approval</span>
                        <span onClick={() => {setShortCut('lastApproved')}} className={`nav-title ${filters['lastApproved'] ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Last approved</span>
                        <span onClick={() => {setShortCut('finishSoon')}} className={`nav-title ${filters['finishSoon'] ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Finish soon</span>
                    </div>
                    <div className='d-md-flex d-none align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
                        <span>
                            <Button label='+ New Plan' className='btn-table' color='primary' onClick={() => { 
                                openNewPlanModal(() => {
                                    refetchPlanList()
                                })
                            }} />
                        </span>
                    </div>
                </div>
                <div className={`d-flex flex-row m-0 p-0 justify-content-between align-items-center`} style={{minHeight: '68px'}}>
                {
              breakWidth === 'SM' ?
                <ModalFilterMobile 
                    setQuery={setFilters} query={filters} 
                    countries={[...new Set(countriesList)]} 
                    graduationDates={arrayGraduationDates} 
                    types={baseTypes}
                    statuses={baseStatuses}    
                >
                  <div className='hover-icon'><IconFilter size={20} className='text-disabled'/></div>
                </ModalFilterMobile> : 

                  
                     <div className={`container-fluid mt-2 mb-4 mt-md-5 px-0 d-flex znk-scroll-horizontal`}>
                        <StatusesFilter
                            statuses={baseStatuses} 
                            statusFilters={filters.status} 
                            setStatusFilters={(newStatus) => {
                                setCurrentPage(1)
                                setFilters({
                                    ...filters, 
                                    status: newStatus,
                                    previous: null,
                                    next: null,
                                })
                            }}
                        />
                        <TypesFilter
                            types={baseTypes} 
                            typesFilters={filters.type} 
                            setTypesFilters={(newTypes) => {
                                setCurrentPage(1)
                                setFilters({...filters, type: newTypes,
                                    previous: null,
                                    next: null,
                                })
                            }}/>
                        <GraduationFilter
                             graduationDates={arrayGraduationDates} 
                             graduationFilters={filters.classOf} 
                             setGraduationFilters={(newTypes) => {
                                setCurrentPage(1)
                                 setFilters({...filters, classOf: newTypes,
                                    previous: null,
                                    next: null,
                                })
                            }}/>
                        <CountryFilter
                            countries={[...new Set(countriesList)]}
                            countryFilters={filters.countryIds}
                            setCountryFilters={(newTypes) => {
                                setCurrentPage(1)
                                setFilters({...filters, countryIds: newTypes,
                                    previous: null,
                                    next: null,
                                })
                            }}/>
                        <DropDownNew
                            className='m-0 p-0'
                            classNameMenu='px-3 pt-3 pb-4'
                            direction='bottomCenter'
                            hoverEnabled={false}
                            trigger={['click']}
                            menuProps={{ triggerSubMenuAction: 'click' }}
                            options={[
                                {
                                component: (
                                    <DropDownSub title={`Start Date`}>
                                    <StartDateFilter
                                        startDateFilter={filters.startDateFilter}
                                        setStartDateFilter={(start) => { setFilters({ ...filters, startDateFilter: start }) }}
                                        typeFilter='StartDate' />
                                    </DropDownSub>
                                )
                                }
                 
                        ]}
              >               
               <Badge className='mx-1 filters' pill color='option'>
              <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                <IconFilter className='mr-2' size={15} />
                {translate('plans.filterTitle')}
              </label>
            </Badge>
          </DropDownNew> 
                     </div>
}
                    {/* <div style> */}
                    {/* <ButtonEmailsList 
                      onClick={() => { openEmailsListModal(() => {}, {...filters, limit: 0}, 'counselingPlan') }} 
                    /> */}
                    {/* </div> */}
                    <InputGroup className={`znk-card ${breakWidth === 'SM' ? 'w-auto' : 'w-25'} flex-nowrap`} title={'Search'} >
                        <input
                            className={"znk-input noRight trans form-control"}
                            placeholder={'Search'}
                            ref={searchInput}
                            onKeyUp={({key}) => {
                                if(key === 'Enter') {
                                    setCurrentPage(1)
                                    setFilters({
                                        ...filters,
                                        search: searchInput.current.value,
                                        next: null, previous: null
                                    })
                                    // setSearchValue(searchInput.current.value)
                                }
                            }}
                        />
                        <InputGroupAddon addonType='prepend' className='icon'>
                            <InputGroupText type='text' className='znk-no-border'>
                            <span className='addon'><IconSearch size={18} className='text-gray' /></span>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>

                    <OptionsEmailsAndDownloads 
                        options={[{title: translate('users.actions.emails'), onClick: () => { openEmailsListModal(() => { }, "counselingPlan", filters) } },
                                {title: translate('users.actions.download'), onClick: () => { executeOnClickDownload() }},
                        ]}
                    loading={loadingExcelInfo}
                />
                </div>
                <div className={`m-0 p-0`}>
                    <TabContent activeTab={loading ? 'loading' : 'table'}>
                        <TabPane tabId={`loading`}>
                            <div className={`p-0 m-2`}>
                                <PlanTableSkeleton count={filters?.limit || 10} />
                            </div>
                        </TabPane>
                        <TabPane tabId={`table`}>
                            <Table
                                loading={false}
                                tableData={planList}
                                columnList={columnsToTable}
                                style={{cursor: 'pointer'}}
                                onClick={(row) => {
                                    openViewPlanModal(() => {refetchPlanList()}, row)
                                }}
                            />
                        </TabPane>
                    </TabContent>
                </div>
            </div>
            <ExcelDownloadComponent loadingExcelInfo={loadingExcelInfo} setLoadingExcelInfo={setLoadingExcelInfo} 
            execDownload={execDownload} setExecDownload={setExecDownload}
            />
            <Pagination
                style={{
                    bottom: '0px',
                    position: 'sticky',
                    backgroundImage: `linear-gradient(
                        to bottom, 
                        rgba(255, 255, 255, 0), 
                        rgba(255, 255, 255, 0.95) 25%
                    )`,
                    paddingTop: '15px'
                }}
                pageSize={filters?.limit || 10}
                changeSize={(limit) => {setFilters({...filters, limit})}}
                clickNext={() => {
                    if(paginatedData?.hasNext && !loading) {
                        setCurrentPage(currentPage+1)
                        setFilters({...filters, next: paginatedData?.next, previous: null})
                    }
                }}
                clickPrevious={() => {
                    if(paginatedData?.hasPrevious && !loading && currentPage > 1) {
                        setCurrentPage(currentPage-1)
                        setFilters({...filters, previous: paginatedData?.previous, next: null})
                    }
                }}
                loading={loading}
                hasPrevious={paginatedData?.hasPrevious}
                hasNext={paginatedData?.hasNext}
                activePage={currentPage}
                pageCount={Math.ceil((paginatedData.totalDocs/filters.limit) || 1)}
            />
        </div>
    )
}

export default CounselingPlans
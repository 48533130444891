import React, { useState, useEffect } from 'react'
import {
  IconClose,
  Button,
  Field,
  Form,
  InputSelect,
  Select,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import { FormGroup, Label } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import { escapeRegExp } from 'lodash'
import useUpdateEducatorAcademicInfo from '../../../Model/useUpdateEducatorAcademicInfo'
import './index.css'
import { showAlert } from '../../../../../common/Alert/util'

const AcademicInfo = (props) => {
  const { toggleRightSide, userData } = props
  const [form, setForm] = useState({})
  const universitiesList = useSelector((state) => state?.modal?.user?.educatorAcademicInfo?.universitiesList)
  const { translate } = useTranslation()
  const lvlEducation = ['associate', 'bachelors', 'masters', 'doctorate']
  const [yearsList, setYearsList] = useState([])
  const dispatch = useDispatch()
  const closeOver = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRate', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRateShort', data: { isOpen: false } })
  }

  const { submit: updateEducatorAcademicInfo, loading: loadingUpdatAcademicInfo/*, data */} = useUpdateEducatorAcademicInfo((resp) => {
    showAlert({
      text: translate('profile.actions.academicInfo.updatedSuccessfully'),
      status: 'success'
    }, dispatch)
  },
    ()=> {showAlert({
      text: translate('profile.actions.academicInfo.updatedError'),
      status: 'error'
    }, dispatch)}
  )

  function rangeYear() {
    const max = new Date().getFullYear()
    const min = 1970
    const years = []

    for (let i = max; i >= min; i--) {
      years.push(i)
    }
    setYearsList(years)
    // return years
  }

  useEffect(() => {
    if (yearsList.length === 0) {
      rangeYear()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setForm({
      educationLevel: userData?.educatorInfo?.educatorAcademicInfo?.educationLevel || '',
      concentration: userData?.educatorInfo?.educatorAcademicInfo?.concentration || '',
      university: userData?.educatorInfo?.educatorAcademicInfo?.university || '',
      graduationDate: userData?.educatorInfo?.educatorAcademicInfo?.graduationDate || null,
    })
  }, [userData])


  const disableButton = () => {
    // eslint-disable-next-line
    return !(form.university
      && form.educationLevel
      && form.graduationDate)
  }

  return (
    <>
      <div className='w-100 py-5 px-8 text-title' style={{ overflowY: 'scroll' }}>
        <span className='d-flex w-100 flex-row justify-content-between'>
          <h2 className='font-weight-bold'>{'Academic Info'}</h2>
          <IconClose size={20} onClick={toggleRightSide || closeOver} color='rgba(119, 134, 153, .4)' />
        </span>

        <>
          <Form
            value={form}
          // onChange={onChange}
          >
            <FormGroup className='mt-5'>
              <Label>{translate('profile.actions.academicInfo.leveleducation')}</Label>
              <Field
                type={InputSelect}
                component={Select}
                fieldName='educationLevel'
                className='h-100 pr-1'
                options={lvlEducation.map((lvlName, idx) => ({
                  label: lvlName?.charAt(0).toUpperCase() + lvlName?.slice(1),
                  value: lvlName
                }))}
                // defaultSelected={form.educationLevel ? form.educationLevel : userData?.educatorInfo?.educatorAcademicInfo?.educationLevel}
                value={form?.educationLevel}
                listItem='small'
                itemDivider='middle'
                multiple={false}
                onChange={(e) => {
                  setForm({
                    ...form,
                    educationLevel: e
                  })
                }}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
            </FormGroup>

            <FormGroup className='mt-5'>
              <Label>{translate('profile.actions.academicInfo.concentration')}</Label>
              <textarea
                id={'concentrationTextArea'}
                value={form?.concentration}
                placeholder='Type here'
                className='znk-input w-100 p-3 mt-2'
                style={{ height: '50px' }}
                onChange={(e) => {
                  setForm({
                    ...form,
                    concentration: e.target.value
                  })
                }}
              />
            </FormGroup>

            <FormGroup className='mt-5'>
              <Label>{translate('profile.actions.academicInfo.university')}</Label>
              <Field
                type={InputSelect}
                component={Select}
                fieldName='university'
                className='h-100 pr-1'
                options={universitiesList?.map((university) => ({
                  label: university?.name,
                  value: university?.id
                }))}
                // defaultSelected={form?.university || ''}
                value={form?.university}
                listItem='small'
                itemDivider='middle'
                multiple={false}
                onChange={(e) => {
                  setForm({
                    ...form,
                    university: e
                  })
                }}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
            </FormGroup>

            <FormGroup className='mt-5'>
              <Label>{translate('profile.actions.academicInfo.graduationyear')}</Label>
              <Field
                type={InputSelect}
                component={Select}
                fieldName='graduationDate'
                className='h-100 pr-1'
                options={yearsList?.map((year) => ({
                  label: year,
                  value: year
                }))}
                // defaultSelected={form?.gradYear}
                value={form?.graduationDate}
                listItem='small'
                itemDivider='middle'
                multiple={false}
                onChange={(e) => {
                  setForm({
                    ...form,
                    graduationDate: e
                  })
                }}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
            </FormGroup>
            <Button
              label={translate('misc.saveChanges')}
              color='primary'
              check
              activity={loadingUpdatAcademicInfo}
              disabled={loadingUpdatAcademicInfo || disableButton()}
              size='medium'
              onClick={() => {
                updateEducatorAcademicInfo({
                  userId: userData.id,
                  educatorAcademicInfo: form,

                })
              }}
              style={{ width: '100%' }}
            />
          </Form>
        </>
      </div>
    </>
  )
}

export default AcademicInfo

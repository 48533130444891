
            // parents {
            //     parentUserId
            //     phone
            //     managePayments
            //     manageApprovals
            //     firstName
            //     lastName
            //     email
            // }


            // parents {
            //     parentUserId
            //     phone
            //     managePayments
            //     manageApprovals
            //     firstName
            //     lastName
            //     email
            // }

            
            // parents {
            //     parentUserId
            //     phone
            //     managePayments
            //     manageApprovals
            //     firstName
            //     lastName
            //     email
            // }
            
            // avatar
            // avatarParent

const getStudentInfoString = `
    ... on LessonsFixedPlan {
        getStudentInfo {
            parents {
                parentUserId
                phone
                managePayments
                manageApprovals
                firstName
                lastName
                email
            }
            studentType
            avatar
            avatarParent
            studentEmail
            
        }
        aditionalInfo {
            educatorsAvatar {
                educatorUserId
                avatar
            }
        }
    }
    ... on LessonsPerWeekPlan {
        getStudentInfo {
            parents {
                parentUserId
                phone
                managePayments
                manageApprovals
                firstName
                lastName
                email
            }
            studentType
            avatar
            avatarParent
            studentEmail
            
        }

        aditionalInfo {
            educatorsAvatar {
                educatorUserId
                avatar
            }
        }
    }
    ... on GroupPLan {
        getStudentsInfo {
            parents {
                parentUserId
                phone
                managePayments
                manageApprovals
                firstName
                lastName
                email
            }
            studentType
            avatar
            avatarParent
            studentEmail
        }
    }
`

const plansQueryResult = `
    ${getStudentInfoString}
    id
    name
    cancelDate
    pauseDate
    resumeDate
    studentUser {
        studentUserId
        firstName
        lastName
        isManualRate
        rate
    }
    serviceId
    lessonIds
    paymentItemIds
    paymentItemsConfig {
        amount
        percentDiscount 
        amountWithoutDiscount
        date
        description
        type
    }
    recommendedEducators {
        educatorUserId
        firstName
        lastName
        rate
        isManualRate
        topicId
        teachingType
        isLockEducator
        lessonLink
        isWaiveIndividual
        isWaiveGroup
    }
    selectedEducators {
        educatorUserId
        firstName
        lastName
        rate
        isManualRate
        topicId
        lessonLink
        isWaiveIndividual
        isWaiveGroup
    }
    status
    kind
    createdAt
    updatedAt  
    isFirstPlan
    typePlanPricing
    pricingRate
    groupPricingRate
    location {
        type
        linkEducator
        linkStudent
    }
    isAtomic
    groupId
    from
    to
    ... on GroupPLan {
    PartsSchedule {
        from
        to
        lessonsPerWeekGroup {
            topicId
            educatorId
            day
            duration
            hour
            totalLessons
        }
        lessonsPerWeekIndividual {
            topicId
            lessonsPerWeek
            lockLessons
            totalLessons
        }
    }
    studentPlans {
        studentId
        planId
        registerDate
        planStatus
    }
    lessonGroupIds
    childPlans {
            id
            name
            cancelDate
            pauseDate
            resumeDate
            ${getStudentInfoString}
            studentUser {
                studentUserId
                firstName
                lastName
                rate
                isManualRate
            }
            serviceId
            lessonIds
            paymentItemIds
            paymentItemsConfig {
                amount
                percentDiscount 
                amountWithoutDiscount
                date
                description
                type
            }
            recommendedEducators {
                educatorUserId
                firstName
                lastName
                rate
                isManualRate
                topicId
                teachingType
                isLockEducator
                lessonLink
                isWaiveIndividual
                isWaiveGroup
            }
            selectedEducators {
                educatorUserId
                firstName
                lastName
                rate
                isManualRate
                topicId
                lessonLink
                isWaiveIndividual
                isWaiveGroup
            }
            status
            kind
            createdAt
            updatedAt  
            isFirstPlan
            typePlanPricing
            pricingRate
            groupPricingRate
            location {
                type
            }
            groupId
            from
            to
            testDate
            ... on LessonsFixedPlan {
                fixedParts: parts {
                TotalPerTopic {
                    topicId
                    lessonsPerWeek
                    lockLessons
                    totalLessons
                }
                type
                totalLessons
                from
                to
                name
                }
            }
            ... on LessonsPerWeekPlan {
                testDate
                parts {
                    lessonPerWeekPerTopic {
                        topicId
                        lessonsPerWeek
                        lockLessons
                        totalLessons
                    }
                    lessonsPerWeek
                    totalLessons
                    from
                    to
                    name
                }
            }          
        }
    }
    ... on LessonsFixedPlan {
        fixedParts: parts {
            TotalPerTopic {
            topicId
            lessonsPerWeek
            lockLessons
            totalLessons
            }
            type
            totalLessons
            from
            to
            name
        }
        groupFixedInfo{
            PartsSchedule {
                lessonsPerWeekGroup {
                    topicId
                    educatorId
                    day
                    duration
                    hour
                    totalLessons
                }
            }
        }
        realAmount {
            totalAmount
        }
    }
    ... on LessonsPerWeekPlan {
        testDate
        parts {
            lessonPerWeekPerTopic {
            topicId
            lessonsPerWeek
            lockLessons
            totalLessons
            }
            lessonsPerWeek
            totalLessons
            from
            to
            name
        }
        groupPerWeekInfo{
            PartsSchedule {
                lessonsPerWeekGroup {
                    topicId
                    educatorId
                    day
                    duration
                    hour
                    totalLessons
                }
            }
        }
        realAmount {
            totalAmount
        }
    }

    checkInDates {
        id
        date
        type
    }
    sendPlanCron {
        sent
        sendDate
    }


`


export default plansQueryResult
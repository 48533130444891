import React, {useState, useMemo} from 'react'
import { 
    ListItem,
    ModalLayout, 
    IconIllustratedAlert,
    Avatar,
    CheckboxOption,
    StickyButton,
    ToolTipInfo
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../../../../i18n/useTranslation'
import useCheckInStudentModal from './useCheckInStudentModal'
import useSendWspAlertForCheckIn from '../../Model/useSendWspAlertForCheckIn'
import {showAlert} from '../../../../../../../common/Alert/util'
import { useEffect } from 'react'
import useCreateNote from '../../Model/useCreateNotes'

const DEFAULT_MESSAGE = `DEFAULT_MESSAGE`
const REASONS = {
    checkinPlan: "checkinPlan",
    scoreExpectation: "scoreExpectation"
}
const CheckInStudentModal = () => {
    const { isOpen, toggle, student, reason, description, onChange, text } = useCheckInStudentModal()
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [usersMessaged, setUsersMessaged] = useState([])
    const [createNote, {loading: loadingCreateNote}] = useCreateNote(
        (res) => {
            if(onChange) onChange()
            showAlert({
                text: 'Success!',
                status: 'success'
            }, dispatch)
        },
        (err) => {
            showAlert({
                text: 'Error!',
                status: 'error'
            }, dispatch)
        }
    )
    const [sendWsp, {loading}] = useSendWspAlertForCheckIn(
        (res) => {
            const messaged = usersMessaged.join(' & ')
            createNote({variables: {
                userId: studentId,
                // content: `Check-In ${reason}. Messaged ${text}`
                content: `***Check-In*** <br>
                ${text} <br>
                Messaged ${messaged}
                `
            }})
        },
        (err) => {
            showAlert({
                text: 'Error!',
                status: 'error'
            }, dispatch)
        }
    )
        
    // const studentName = useMemo(() => `${student?.firstName} ${student?.lastName}`, [student])
    const studentId = useMemo(() => student?.id || student?._id, [student])

    const users = useMemo(
        () => {
            const validParents = (student?.studentInfo?.parents || [])
                .filter(({manageApprovals, managePayments}) => manageApprovals && managePayments)
                .map((parent) => {return {...parent, id: parent?.id || parent?._id || parent?.parentUserId}})
            return [student].concat(validParents)
        }, [student]
    )
    const [messages, setMessages] = useState([])
    useEffect(() => {
        setMessages(
            users.map((student) => {
                return {
                    ...student,
                    message: '', 
                    userId: student?.id || student?._id,
                    check: false
                }
            })
        )
    }, [users]) 
    const buttonDisabled = useMemo(
        () => {
            const checkedUsers = (messages || []).filter(({check}) => check) 
            const rolesCheckedArray = []
            checkedUsers.map((user) => user.isParent ? rolesCheckedArray.push('Parent') : rolesCheckedArray.push('Student') )
            setUsersMessaged([...new Set(rolesCheckedArray)])
            return !(checkedUsers?.length > 0)
        }, [messages]
    )

    const isCheckInReason = useMemo(() => reason === REASONS.checkinPlan || REASONS.scoreExpectation, [reason])

    return (
        <ModalLayout
            className='modal-colored modal-centered fixed-head-modal'
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className='d-flex flex-column'>
                    <IconIllustratedAlert className='mb-3' size={80} />
                    <span className='h2 font-weight-bold'>{translate("dashboard.issueCheckIn.listItem.sendWspModal.title") || "Check-In Student"}</span>
                </div>
            }
            disabledScrollAnimation
        >
            <div className='m-0 p-0'>
                <span className='text-gray'>{description}</span>
                <div className='d-flex flex-column py-4'>
                    {
                        messages.map((user) => {
                            const isStudent = studentId === user?.userId
                            const userName = `${user?.firstName} ${user?.lastName}`
                            return (
                                <ListItem
                                    className='py-2 mb-1'
                                    Left={
                                        <div className={`d-flex flex-row align-items-baseline ${!isStudent ? 'pl-2' : ''}`}>
                                            <Avatar
                                                name={userName}
                                            />
                                            <span className='ml-2'>{userName}</span>
                                            <span className='text-gray h5 ml-1'>{`(${isStudent ? translate("dashboard.issueCheckIn.listItem.sendWspModal.student") : translate("dashboard.issueCheckIn.listItem.sendWspModal.parent")})`}</span>
                                        </div>
                                    }
                                    Right={
                                        <ToolTipInfo
                                            disable={!(isStudent && isCheckInReason)}
                                            tooltipId={`check-box-${user?.userId}`}
                                            innerElement={<span className='text-white'>Disabled for students on check-in notifications.</span>}
                                            place='top'
                                        >
                                            <CheckboxOption
                                                disabled={isCheckInReason && isStudent}
                                                defaultState={false}
                                                onChange={(newValue) => {
                                                    setMessages(messages.map(
                                                        (auxUser) => {
                                                            if(auxUser?.userId === user?.userId) return {
                                                                ...auxUser,
                                                                isParent: !isStudent,
                                                                check: newValue
                                                            }
                                                            return auxUser
                                                        }
                                                    ))
                                                }}
                                            />
                                        </ToolTipInfo>
                                    }
                                />

                            )
                        })
                    }

                    {/* <textarea
                        style={{minHeight: '100px'}}
                        className='w-100 p-2 znk-input'
                        value={DEFAULT_MESSAGE}
                    /> */}
                </div>

                <StickyButton
                    disabled={buttonDisabled}
                    onClickButtonFunction={() => {
                        const variables = {
                            studentId,
                            reason,
                            messages: messages.filter(({check}) => check).map(({userId, isParent}) => {
                                return {
                                    message: DEFAULT_MESSAGE,
                                    userId, isParent
                                }
                            }),
                            checkInId: student?.checkInDate?.id
                        }
                        sendWsp({variables})
                    }}
                    activity={loading || loadingCreateNote}
                    color='#696CFF'
                    className='w-100 mb-3'
                    size='medium'
                    label={translate("dashboard.issueCheckIn.listItem.sendWspModal.buttonLabel")}
                />
            </div>
        </ModalLayout>
    )
}

export default CheckInStudentModal
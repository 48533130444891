import React, { useState } from 'react'
import {ScheduledCalendarForFinder, Avatar} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useMyFreeTimesFrames from '../../Model/useMyFreeTimesFrames'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import './index.css'

const EducatorGrid = (props) => {
    const {
        educator = {}, start = new Date(), end = new Date(), 
        zone, className = '',
        frames = []
    } = props
    const {translate, replaceVariables} = useTranslation()
    const [query] = useState({
        involveMyself: false,
        studentIds: [],
        educatorUserId: educator?.id,
        minDurationInMinutes: 50,
        from: new Date(start),
        to: new Date(end)
    }) 

    const {data, loading} = useMyFreeTimesFrames(query)
    return (
        <div className={`d-flex flex-column ${className}`} style={{gap: 10}}>
            <div className='w-100 d-flex flex-row justify-content-start align-items-center' style={{gap: 10}}>
                <Avatar
                    name={`${educator?.firstName} ${educator?.lastName}`}
                />
                <span>{`${educator?.firstName} ${educator?.lastName}`} <small className='text-gray'>{`(${translate("modal.newplan.selecteducator.finder.educator")})`}</small></span>
            </div>
            <span className='h45 text-muted'>{replaceVariables(translate("modal.newplan.selecteducator.finder.blocsInSearch"), {BLOCS: frames?.length}) || `${frames?.length} blocs in search`}</span>
            <span className='h45 text-muted'>{
                replaceVariables(translate("modal.newplan.selecteducator.finder.rangeSearchLabel"), {
                    START: start ? moment(start).format('MMMM Do').toUpperCase() : '',
                    END: end ? moment(end).format('MMMM Do').toUpperCase() : ''
                }) ||
                `No defined`
            }</span>
            <ScheduledCalendarForFinder
                disableTooltip
                loading={loading}
                setSelected={() => {}}
                selectedDay={[]}
                educator={{}}
                checkedDays={
                    frames
                    // (educator?.freeFramesBlocs||[]).map((time) => ({ educator: {}, time: moment(time) }))
                }
                overCheckCustomClassName={'matchBlockEducatorFinder'}
                checkCustomClassName={'unmatchBlockEducatorFinder'}
                myFreeTimeFrames={data?.myFreeTimeFrames || []}
                from={start ? moment(start) : moment()}
                fromPart={start}
                toPart={end}
                zone={zone}
                // lang={selectedLang}
                className='pb-6 pt-5'
                getIndexOfLesson={() => {}}
                overCheckedDays={
                    (educator?.freeFramesBlocs || []).map(
                        (time) => ({
                            educator: {},
                            time: moment(time) 
                        })
                    )
                }
                useSecondaryStyle
            />
        </div>
    )
}

export default EducatorGrid
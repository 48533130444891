import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import {
  ModalLayout,
  useWindowDimensions,
  ButtonOptions,
  Field,
  CheckboxOption,
  ListItem,
  Avatar,
  Form,
  PlanCounselingCard,
  IconIllustratedReminder
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import { showAlert } from '../../../../common/Alert/util'
import useSendOnePlanModal from './useSendOnePlanModal'
import useSendCouncelingPlanFromAdmin from '../../Model/useSendCouncelingPlanFromAdmin'
import TimelineShoot from './GetImages/Timeline'
import ProposalShoot from './GetImages/Proposal'
import { toPng } from 'html-to-image'
import useUploadImageBase64 from '../../../CounselingPlans/Model/useUploadImageBase64'
import { useDispatch } from 'react-redux'
import useUser from '../../Model/useUser'
import ProgramSentPlan from '../../../Plans/modal/SendOnePlan/Components/ProgramSentPlan'
import ProgramSentPlanLabel from '../../../Plans/modal/SendOnePlan/Components/ProgramSentPlanLabel'
import SendWspItem from '../../../Plans/modal/Components/sendWspItem'

import { typeDictionary } from '../../Utils/typePlanDictionary'
const SendPlanModal = () => {
  const [sendWspState, setSendWspState] = useState(false)
  const { breakWidth } = useWindowDimensions()
  const { toggle, isOpen, onChange, dataPlan, loading, useMutation, refetch = () => {} } = useSendOnePlanModal()

  //sendPlanCron data
  const { sent, sendDate: sendDateFromData } = dataPlan?.sendPlanCron || {}
  const hasProgramSent = useMemo(() => !!sendDateFromData && !!!sent, [sendDateFromData, sent])

  const [programData, setProgramData] = useState([])
  const [sendDate, programPlan] = programData
  const BUCKET = useMemo(
    () => {
      return `media.zinkerz.com/counseling/${dataPlan?.studentUser?.studentUserId}/plan${dataPlan?.id}`
    }, [dataPlan]
  )

  const dispatch = useDispatch()
  const callBack = () => { onChange() }
  const [sendPlans, { loading: loadSendPlans }] = useSendCouncelingPlanFromAdmin(
    () => {
      showAlert({
        text: 'Plan has been sent successfully!',
        status: 'success'
      }, dispatch)
      callBack()
    },
    (err) => {
      showAlert({
        text: err.toString(),
        status: 'error'
      }, dispatch)
      console.error(err)
    }
  )

  const [form, setForm] = useState({
    student: false,
    parent: []
  })
  // const tasks = useMemo(() => {
  //     return dataPlan?.tasks?.reduce((array, {steps}) => {
  //       return array.concat(
  //           steps.reduce((array2, {tasks}) => {
  //               return array2.concat(tasks)
  //           }, [])
  //       )
  //     }, [])
  // }, [dataPlan])


  const timelineRef = useRef(null)
  const proposalRef = useRef(null)

  const [timelineUrl, setTimelineUrl] = useState(null)
  const [proposalUrl, setProposalUrl] = useState(null)
  useEffect(() => {
    toPng(
      timelineRef?.current,
      // , {width: 500, height: 500}
    )
      .then((dataUrl) => {
        setTimelineUrl(dataUrl)
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error)
      })
  }, [timelineRef, dataPlan])

  useEffect(() => {

    setTimeout(() => {

      toPng(
        proposalRef?.current,
        // , {width: 500, height: 500}
      )
        .then((dataUrl) => {
          setProposalUrl(dataUrl)
        })
        .catch((error) => {
          console.error('oops, something went wrong!', error)
        })

    }, 5000)

  }, [proposalRef, dataPlan])

  const onChangeParentFn = (id) => {
    let arrayIds = []
    if (form?.parent.includes(id)) {
      arrayIds = form?.parent.filter(p => p !== id
      )
    } else {
      arrayIds = form?.parent
      arrayIds.push(id)
    }
    setForm({ ...form, parent: arrayIds })
  }

  const onSendPlan = useCallback((uploadImageBase64) => {
    const [sendDate] = programData
    const variables = {
      input: {
        planId: dataPlan?.id,
        sendToStudent: form.student,
        sendToParent: form?.parent.length > 0,
        sendWspToParent: sendWspState,
        timelimeImage: uploadImageBase64,
        proposalImage: form.proposalUrl,
        userParentIds: form?.parent || [],
      }
    }
    if (sendDate) variables.sendDate = sendDate
    sendPlans({ variables })
  }, [form, dataPlan, programData, sendPlans, sendWspState])

  const [uploadImageTimeline, { loading: loadUploadTimeline }] = useUploadImageBase64(
    (res) => {
      onSendPlan(res.uploadImageBase64)
    },
    (err) => {
      console.error(err)
    }
  )

  const [uploadImageProposal, { loading: loadUploadProposal }] = useUploadImageBase64(
    (res) => {
      setForm({
        ...form, proposalUrl: res.uploadImageBase64
      })
      uploadImageTimeline({
        variables: {
          bucket: BUCKET,
          file: timelineUrl
        }
      })
    },
    (err) => {
      console.error(err)
    }
  )



  // sendCouncelingPlanFromAdmin

  const { data: studentData } = useUser({ id: dataPlan?.studentUser?.studentUserId })
  const parent = useMemo(
    () => {
      if (studentData?.user?.studentInfo?.studentType === 'family') {
        // let activeParent = studentData.user.studentInfo.parents?.find(({manageApprovals}) => manageApprovals)
        let allParents = studentData.user.studentInfo.parents
        // if(!activeParent) activeParent = studentData.user.studentInfo.parents[0]
        return allParents || null
      }
      return null
    }, [studentData]
  )

  const submitPlan = () => {
    if (useMutation) {
      uploadImageProposal({
        variables: {
          bucket: BUCKET,
          file: proposalUrl
        }
      })
    } else callBack()
  }

  return (
    <>
      <ModalLayout
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'
          } modal-colored h-auto`}
        isOpen={isOpen}
        toggle={toggle}
        topHead={
          <div className={`d-flex flex-column`}>
            <IconIllustratedReminder size={80} />
            <span className={`h2 text-title font-weight-bold mt-2`}>Send counseling plan</span>
          </div>
        }
      >
        <div className='m-0 p-0'>
          <Form value={form} onChange={setForm}>
            {
              dataPlan && dataPlan.studentUser && (
                <ListItem
                  className='py-2'
                  label={null}
                  Left={
                    <div className='d-flex align-items-center'>
                      <Avatar
                        name={`${dataPlan.studentUser.firstName} ${dataPlan.studentUser.lastName}`}
                        named={false}
                        uri={dataPlan?.studentUser?.avatar}
                        size='avatar-small'
                      />
                      <label className='ml-2 h4'>
                        {`${dataPlan.studentUser.firstName} ${dataPlan.studentUser.lastName}`}
                        <span className='h5 text-gray'> {`(Student)`}</span>
                      </label>
                    </div>
                  }
                  Right={
                    <Field
                      type={CheckboxOption}
                      fieldName='student'
                      checked={form.student}
                    />
                  }
                />
              )
            }
            {
              parent?.length > 0 && parent.map((p, idx) =>
              (
                <ListItem
                  className='py-2'
                  label={null}
                  id={idx}
                  Left={
                    <div className='d-flex align-items-center'>
                      <Avatar
                        name={`${p?.firstName} ${p?.lastName}`}
                        named={false}
                        uri={p?.avatar}
                        size='avatar-small'
                      />
                      <label className='ml-2 h4'>
                        {`${p?.firstName} ${p?.lastName}`}
                        <span className='h5 text-gray'> {`(Parent)`}</span>
                      </label>
                    </div>
                  }
                  Right={
                    <Field
                      id={idx}
                      type={CheckboxOption}
                      fieldName={idx}
                      checked={form?.parent.includes(p.parentUserId)}
                      onChange={() => {
                        onChangeParentFn(p.parentUserId)
                      }}
                    />
                  }
                />
              )
              )
            }
            {
              dataPlan && (
                <PlanCounselingCard
                  title={dataPlan?.name}
                  // subtitle = {`${moment(dataPlan?.from).format('MMM DD, YYYY')} to ${moment(dataPlan?.to).format('MMM DD, YYYY')}`}
                  subtitle={`${typeDictionary[dataPlan?.type] || 'Type'} | ${dataPlan?.aditionalInfo?.studentCountryName || 'Country'} | Until ${moment(dataPlan?.to).format('MMM DD, YYYY')}`}
                  // text = {`${tasks?.length} tasks`}
                  text={''}
                />
              )
            }
          </Form>
        </div>


        {
          hasProgramSent && (
            <ProgramSentPlanLabel planId={dataPlan.id} onDelete={() => {
              if(refetch) refetch()
            }} sendDate={sendDateFromData} />
          )
        }
        {
          parent?.length > 0 && (
            <SendWspItem
              sendWspState={sendWspState}
              setSendWspState={setSendWspState}
              dataPlan={dataPlan}
            />
          )
        }

        <ButtonOptions
          color='#696CFF'
          style={{ backgroundColor: '#696CFF' }}
          className='w-100 mt-3'
          disabled={!(form.student || form.parent.length > 0) || !!!proposalUrl || !!!timelineUrl || !((programPlan && sendDate) || !programPlan)}
          activity={loading || loadSendPlans || !timelineRef || !proposalRef || loadUploadProposal || loadUploadTimeline || !!!proposalUrl || !!!timelineUrl}
          label={programPlan ? 'Program Send Plan' : 'Send plan'}
          onClick={() => {
            submitPlan()
          }}
          options={[
            {
              component:
                <div>
                  <ProgramSentPlan
                    onProgram={(sendDate) => {
                      setProgramData([sendDate, true])
                      submitPlan()
                    }}
                  />
                </div>
            }
          ]}
        />


      </ModalLayout>
      <div style={{ backgroundColor: 'white', position: 'absolute', top: 0, left: 0, width: '700px', maxHeight: '0px', overflow: 'hidden' }}>
        <div className={'w-100'} ref={timelineRef}>
          <TimelineShoot plan={dataPlan} />
        </div>
        <div className={'w-100'} ref={proposalRef} style={{ backgroundColor: 'white' }} >
          <ProposalShoot className={'w-100'} plan={dataPlan} />
        </div>
      </div>
    </>
  )
}

export default SendPlanModal

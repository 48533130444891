import React from 'react'
import {
  ListItem,
  Avatar,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import SelectEducator from '../../../Plans/modal/newPlan/SelectEducator'

const Educator = (props) => {
  const { form, setForm, className, isEdit } = props
  const { translate } = useTranslation()
  return (
    <>
      <div className={`m-0 p-0 ${className}`}>
        <h3 className='mb-3'><b>{translate(form?.isMeeting ? 'modal.newlesson.counselor' : 'modal.newlesson.educator')}</b></h3>
        <SelectEducator
          isNewLesson
          idTopic={form?.topic?.id || ''}
          topicName={form?.topic?.name || ''}
          form={form}
          idService={form?.service?.id || ''}
          setEducator={(edu) => {
            //obtiene el objeto tópico seleccionado
            let waive = false
            let rate = edu?.educatorInfo?.services
              .find((serv) => serv.serviceId === form?.service?.id).topics
              .find((topic) => topic.topicId === form?.topic?.id)
            //usa el individual rate o el group rate según el tipo de lección
            if(form.typeLesson === 'group') {
              waive = rate?.isWaiveIndividual
              rate = rate?.groupRate || 0
            }
            else {
              waive = rate?.isWaiveGroup
              rate = rate?.individualRate || 0
            }
            setForm({
              ...form,
              educator: edu,
              educatorRate: rate,
              waiveEducatorRate: waive,
              isEducatorRate: true
            })
          }}
          educator={form.educator || {}}
        >
          {form.educator
            ? <ListItem
              className='py-1'
              id='x-0'
              label={null}
              Left={
                <div className='d-flex align-items-center'>
                  <Avatar
                    name={`${form.educator.firstName} ${form.educator.lastName}`}
                    named={false}
                    uri={null}
                    size='avatar-medium'
                  />
                  <label className='ml-2 h4'>
                    {`${form.educator.firstName} ${form.educator.lastName}`}
                  </label>
                </div>
              }
              Right={<IconArrowRight />}
              />
            : <label className='btn-link'>{translate('modal.newlesson.selecteducator')}</label>}
        </SelectEducator>
        {
          isEdit &&
          (
            <>
              <label className='btn-link'>Edit wage and comment</label>
            </>
          )
        }
      </div>
    </>
  )
}

export default Educator
